import React from 'react';
import { useLoading } from '../../contexts/LoadingContext';
import './LoadingOverlay.scss';
import { Spinner } from '../../assests/icons/icons';

/**
 * `LoadingOverlay` is a component that displays a spinner covering the entire screen,
 * blocking user interaction while the loading state is `true`.
 * It is used to indicate that an asynchronous operation is in progress.
 */
export const LoadingOverlay: React.FC = () => {
    const { loadingConfig } = useLoading();
    const { loading, spinner } = loadingConfig;

    if (!loading) return null;

    return <div className="loading-overlay">{spinner && <Spinner />}</div>;
};
