import React from 'react';
import { Button, Modal } from '@vacasa/react-components-lib';
import { Spinner } from '../../assests/icons/icons';
import './RefreshModal.scss';

interface RefreshModalProps {
    showModal: boolean;
    onHide: () => void;
    handleAction: () => void;
    isLoading: boolean;
}

const RefreshModal: React.FC<RefreshModalProps> = (props) => {
    const { showModal, onHide, handleAction, isLoading } = props;
    return (
        <Modal showModal={showModal} setShowModal={onHide} size={'medium'}>
            <div>
                <span className={'body-bold'}>{`Refresh Review`}</span>
            </div>

            <div>
                {isLoading ? (
                    <div className="refresh-processing-spinner">
                        <Spinner />
                    </div>
                ) : (
                    <div>
                        <div className={'refresh-review-msg'}>
                            <span className={'confirmation-text'}>
                                The review has already been assigned, we recommend refreshing the page to get the updated data.
                            </span>
                        </div>
                        <div>
                            <div className="refresh-review-actions">
                                <Button customClass={'refresh-cancel-btn'} variant="info" onClick={onHide}>
                                    Cancel
                                </Button>
                                <Button variant="secondary" onClick={() => handleAction()}>
                                    {'Refresh Review'}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default RefreshModal;
