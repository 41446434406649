import * as _ from 'lodash';
import { ReviewStatus } from '@reviews/interfaces';

export type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const isNumber = (value: string): boolean => {
    const num = value.match(/^[0-9]+$/);
    return !_.isNil(num);
};

export const isEmail = (email: string): boolean => {
    const result = email.match(
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return !_.isNil(result);
};

export function numberWithCommas(x: number | null | undefined): string {
    if (_.isNil(x)) {
        return '0';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const openInNewTab = (url: string, baseUrl: string | undefined) => {
    const openUrl = (baseUrl ?? '').concat(url);
    window.open(openUrl, '_blank', 'noopener,noreferrer');
};

export function isInsideIFrame(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const isProcessedReview = (status: ReviewStatus): boolean => {
    return status === ReviewStatus.SUPPRESSED || status === ReviewStatus.PUBLISHED;
};

export const getDiffTimeInSeg = (curr: number, start: number): number => {
    return (curr - start) / 1000;
};
