import { AutoCompleteSelect, Button } from '@vacasa/react-components-lib';
import React from 'react';
import { Roles, UserResponse } from '@reviews/interfaces';
import { mapToUserSelectList } from './AutCompleteUsers.utils';
import './AutoCompleteUsers.scss';
import * as _ from 'lodash';
import { getUserLoggedContext } from '../../contexts/userContext';

export interface AutoCompleteUsersProps {
    userList: UserResponse[];
    selectedUser: { value: string; display: string };
    onChangeUser: (val: { value: string; display: string }) => void;
    onClick: () => void;
    selectedReviewNumber: number;
}

export const AutoCompleteUsers: React.FC<AutoCompleteUsersProps> = (props) => {
    const { userList, selectedUser, onChangeUser, onClick, selectedReviewNumber } = props;
    const isEmployee = getUserLoggedContext() && getUserLoggedContext().role === Roles.EMPLOYEE;

    const userSelectList = mapToUserSelectList(userList);

    const onChange = (newValue: { value: string; display: string } | null) => {
        if (newValue) {
            onChangeUser(newValue);
        }
    };
    const isValid = !_.isNil(selectedUser) && !_.isEqual(selectedUser, { value: '', display: '' }) && selectedReviewNumber > 0;

    return !isEmployee ? (
        <div className={'assign-to-cta '}>
            <span className={'body-bold'}>{'Assign to:'}</span>
            <AutoCompleteSelect
                customClass={'auto-complete'}
                options={userSelectList}
                value={selectedUser}
                onChange={onChange}
                getOptionLabel={(options) => options.display}
            />
            <Button customClass={'assign-btn'} onClick={onClick} disabled={!isValid} variant="secondary">
                Assign
            </Button>
        </div>
    ) : (
        <>
            <br />
            <br />
        </>
    );
};
