import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Configuration } from '../Configuration';
import { GetUsersResponse, UserResponse } from '@reviews/interfaces';
import { flowId, StorageKeys } from '@reviews/frontend';
import { ApiRequest } from './ReviewsService';
import * as _ from 'lodash';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${Configuration.getReviewsManagementAPIUrl()}/management/v1`,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${localStorage.getItem(StorageKeys.ACCESS_TOKEN)}`);
            headers.set('x-identity-token', `${localStorage.getItem(StorageKeys.ID_TOKEN)}`);
            headers.set('X-Flow-Id', flowId);
            headers.set('X-Client-Identifier', 'reviews-management-app');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUsers: builder.query<GetUsersResponse, ApiRequest>({
            query: (req: ApiRequest) => {
                const filters = _.reduce(
                    req.filters,
                    (acc, curr, key) => {
                        acc[`filter[${key}]`] = curr;
                        return acc;
                    },
                    {} as { [key: string]: any }
                );
                const params = {
                    include: req.include,
                    ...filters,
                    'filter[role][in]': 'AGENT,ADMIN,TEAM_LEAD',
                    'page[size]': 500,
                    'page[number]': 1,
                    sort: 'name',
                };
                return {
                    url: 'users',
                    method: 'GET',
                    params,
                };
            },
            keepUnusedDataFor: 600,
            providesTags: ['Users'],
        }),

        getUserRole: builder.query<{ data: UserResponse }, { email: string }>({
            query: (req: { email: string }) => ({
                url: '/users/role',
                method: 'GET',
                params: { 'filter[email]': req.email },
            }),
        }),
    }),
});

export const { useGetUsersQuery, useLazyGetUserRoleQuery } = usersApi;
