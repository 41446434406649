import { HeaderBulkTypes } from '@reviews/interfaces';
import { isBefore, isValid, format } from 'date-fns';
import * as _ from 'lodash';
import { isEmail, isNumber } from '../../utils/shared.utils';
import { toDate } from 'date-fns-tz';

export const validateBulkData = (header: HeaderBulkTypes, value: string, errors: Array<string>): void => {
    let condition: boolean;
    switch (header) {
        case HeaderBulkTypes.DISPLAY_REVIEW:
            condition = isNumber(value) && (_.toNumber(value) === 1 || _.toNumber(value) === 0);
            if (!condition) {
                errors.push('Display review must be just number 1 or 0');
            }
            break;
        case HeaderBulkTypes.GUEST_EMAIL:
            if (!isEmail(value)) {
                errors.push('Invalid email');
            }
            break;
        case HeaderBulkTypes.GUEST_FIRST_NAME:
            if (isNumber(value)) {
                errors.push('GuestFirstName no numbers in name');
            }
            break;
        case HeaderBulkTypes.GUEST_LAST_NAME:
            if (isNumber(value)) {
                errors.push('GuestLastName no numbers in last name');
            }
            break;
        case HeaderBulkTypes.OVERALL_RATING:
            condition = isNumber(value) && _.toNumber(value) > 0 && _.toNumber(value) <= 5;
            if (!condition) {
                errors.push('Overall must be a number between 1 - 5');
            }
            break;
        case HeaderBulkTypes.PUBLIC_REVIEW:
        case HeaderBulkTypes.PUBLIC_REVIEW_TITLE:
            break;
        case HeaderBulkTypes.REVIEW_DATE:
            if (_.isEmpty(value)) {
                errors.push('Review Date is required');
                return;
            }
            const date = toDate(_.toString(value));
            if (!isValid(date)) {
                errors.push('Invalid date');
                return;
            }
            const formatDate = format(date, 'yyyy-MM-dd');
            if (!_.isEqual(formatDate, value)) {
                errors.push('Invalid format date');
                return;
            }
            condition = isBefore(date, new Date());
            if (!condition) {
                errors.push('Date in the future');
            }
            break;
        case HeaderBulkTypes.UNIT_ID:
            if (!isNumber(value)) {
                errors.push('UnitID must be just number');
            }
            break;
    }
};

const headers = [
    HeaderBulkTypes.UNIT_ID,
    HeaderBulkTypes.REVIEW_DATE,
    HeaderBulkTypes.GUEST_EMAIL,
    HeaderBulkTypes.GUEST_FIRST_NAME,
    HeaderBulkTypes.GUEST_LAST_NAME,
    HeaderBulkTypes.OVERALL_RATING,
    HeaderBulkTypes.DISPLAY_REVIEW,
    HeaderBulkTypes.PUBLIC_REVIEW_TITLE,
    HeaderBulkTypes.PUBLIC_REVIEW,
];

export const headersDefault: Array<{ label: string; key: string }> = _.map(headers, (h) => ({ label: h, key: h }));
export const headersErrors: Array<{ label: string; key: string }> = [
    ...headersDefault,
    {
        label: 'Detail',
        key: 'Detail',
    },
];
export const headersResult: Array<string> = [...headers, 'Detail', 'ReviewID'];
