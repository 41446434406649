import {
    BulkImportStatus,
    GetBulkImports,
    GetReviewsResponse,
    GetTeamDetail,
    MaintenanceTicketResponse,
    ReviewGetResponse,
    ReviewTicketResponse,
    UnitResponse,
    UserResponse,
} from '@reviews/interfaces';
import * as _ from 'lodash';
import { format, formatDistance } from 'date-fns';
import { Button, Icon } from '@vacasa/react-components-lib';

export enum QueueTypes {
    NEW = 'NEW',
    MINE = 'MINE',
    SEARCH = 'SEARCH',
    BULK_IMPORT = 'BULK_IMPORT',
    RESPONSE_REQUIRED = 'RESPONSE_REQUIRED',
    MY_TEAM = 'MY_TEAM',
}

export const MapQueueTypeToString: { [key: string]: string } = {
    [QueueTypes.NEW]: 'New',
    [QueueTypes.MINE]: 'Mine',
    [QueueTypes.SEARCH]: 'Search',
    [QueueTypes.BULK_IMPORT]: 'Bulk Import',
    [QueueTypes.RESPONSE_REQUIRED]: 'Response Required',
    [QueueTypes.MY_TEAM]: 'My Team',
};

export interface HeadCell {
    id: string;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    action?: boolean;
    actionModal?: boolean;
    iconAction?: any;
    iconNoAction?: any;
    link?: true;
    bulkPermission?: true;
    sortable: boolean;
}

interface ReviewTableObject {
    review_id: number;
    review_date: string;
    age: string;
    rating?: number;
    unit_code?: string;
    reservation_id?: number;
}

export const newHeaders: HeadCell[] = [
    {
        id: 'select',
        numeric: false,
        disablePadding: true,
        label: '',
        sortable: false,
    },
    {
        id: 'review_id',
        numeric: false,
        disablePadding: true,
        label: 'Review ID',
        sortable: true,
    },
    {
        id: 'review_date',
        numeric: false,
        disablePadding: false,
        label: 'Review Date',
        sortable: true,
    },
    {
        id: 'admin_unit_id',
        numeric: false,
        disablePadding: false,
        label: 'Unit ID',
        sortable: true,
    },
    {
        id: 'unit_code',
        numeric: false,
        disablePadding: false,
        label: 'Unit Code',
        sortable: true,
    },
    {
        id: 'reservation_id',
        numeric: false,
        disablePadding: false,
        label: 'Reservation ID',
        sortable: true,
    },
    {
        id: 'rating',
        numeric: false,
        disablePadding: false,
        label: 'Rating',
        sortable: true,
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: 'Age',
        sortable: false,
    },

    {
        id: 'action',
        action: true,
        iconAction: <Icon.ChevronRight height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
];

interface MyWorkTableObject {
    review_id: number;
    review_date: string;
    rating?: number;
    unit_code?: string;
    reservation_id?: number;
    status?: string;
    assigned_to?: string;
    assigned_on?: string;
}

export const myWorkHeaders: HeadCell[] = [
    {
        id: 'select',
        numeric: false,
        disablePadding: true,
        label: '',
        sortable: false,
    },
    {
        id: 'review_id',
        numeric: false,
        disablePadding: true,
        label: 'Review ID',
        sortable: true,
    },
    {
        id: 'review_date',
        numeric: false,
        disablePadding: false,
        label: 'Review Date',
        sortable: true,
    },
    {
        id: 'admin_unit_id',
        numeric: false,
        disablePadding: false,
        label: 'Unit ID',
        sortable: true,
    },
    {
        id: 'unit_code',
        numeric: false,
        disablePadding: false,
        label: 'Unit Code',
        sortable: true,
    },
    {
        id: 'reservation_id',
        numeric: false,
        disablePadding: false,
        label: 'Reservation ID',
        sortable: true,
    },
    {
        id: 'rating',
        numeric: false,
        disablePadding: false,
        label: 'Rating',
        sortable: true,
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: 'Age',
        sortable: false,
    },

    {
        id: 'assigned_to',
        numeric: false,
        disablePadding: false,
        label: 'Assigned To',
        sortable: true,
    },
    {
        id: 'assigned_on',
        numeric: false,
        disablePadding: false,
        label: 'Assigned on',
        sortable: true,
    },
    {
        id: 'action',
        action: true,
        iconAction: <Icon.ChevronRight height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
];

interface ReviewTicketTableObject {
    review_id: number;
    review_date: string;
    unit_code: string;
    reservation_id?: number;
    assigned_to: string;
    ticket: number;
    lom_name: string;
    update: string;
    ticket_created_at: string;
    ticket_id: number;
    follow_up_status: string;
    rating?: number;
}

export const reviewTicketHeader: HeadCell[] = [
    {
        id: 'select',
        numeric: false,
        disablePadding: true,
        label: '',
        sortable: false,
    },

    {
        id: 'review_id',
        numeric: false,
        disablePadding: true,
        label: 'Review ID',
        sortable: true,
    },
    {
        id: 'review_date',
        numeric: false,
        disablePadding: false,
        label: 'Review Date',
        sortable: true,
    },
    {
        id: 'admin_unit_id',
        numeric: false,
        disablePadding: false,
        label: 'Unit ID',
        sortable: true,
    },
    {
        id: 'unit_code',
        numeric: false,
        disablePadding: false,
        label: 'Unit Code',
        sortable: true,
    },
    {
        id: 'reservation_id',
        numeric: false,
        disablePadding: false,
        label: 'Reservation ID',
        sortable: true,
    },
    {
        id: 'rating',
        numeric: false,
        disablePadding: false,
        label: 'Rating',
        sortable: true,
    },

    {
        id: 'assigned_to',
        numeric: false,
        disablePadding: false,
        label: 'Assigned To',
        sortable: true,
    },
    {
        id: 'ticket_created_at',
        numeric: false,
        disablePadding: false,
        label: 'Ticket Date',
        sortable: true,
    },
    {
        id: 'ticket',
        numeric: false,
        disablePadding: false,
        link: true,
        label: 'Ticket',
        sortable: true,
    },

    {
        id: 'follow_up_date',
        numeric: false,
        disablePadding: false,
        label: 'Follow Up Date',
        sortable: true,
    },
    {
        id: 'update',
        numeric: false,
        disablePadding: false,
        label: 'Last Update',
        sortable: true,
    },

    {
        id: 'actionModal',
        action: false,
        actionModal: true,
        iconAction: <Icon.FilePlus height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
    {
        id: 'action',
        action: true,
        iconAction: <Icon.ChevronRight height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
];

interface SearchTableObject {
    review_id: number | string;
    review_date: string;
    updated_at: string;
    rating?: number | string;
    unit_code?: string;
    reservation_id?: number | string;
    assigned_to?: string;
    status?: string;
}

export const searchHeaders: HeadCell[] = [
    {
        id: 'select',
        numeric: false,
        disablePadding: true,
        label: '',
        sortable: false,
    },
    {
        id: 'review_id',
        numeric: false,
        disablePadding: true,
        label: 'Review ID',
        sortable: true,
    },
    {
        id: 'review_date',
        numeric: false,
        disablePadding: false,
        label: 'Review Date',
        sortable: true,
    },
    {
        id: 'admin_unit_id',
        numeric: false,
        disablePadding: false,
        label: 'Unit ID',
        sortable: true,
    },
    {
        id: 'unit_code',
        numeric: false,
        disablePadding: false,
        label: 'Unit Code',
        sortable: true,
    },
    {
        id: 'reservation_id',
        numeric: false,
        disablePadding: false,
        label: 'Reservation ID',
        sortable: true,
    },
    {
        id: 'rating',
        numeric: false,
        disablePadding: false,
        label: 'Rating',
        sortable: true,
    },

    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Update',
        sortable: true,
    },
    {
        id: 'assigned_to',
        numeric: false,
        disablePadding: false,
        label: 'Assigned To',
        sortable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: true,
    },
    {
        id: 'action',
        action: true,
        iconAction: <Icon.ChevronRight height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
];

interface BulkImportTableObject {
    bulk_import_id: number;
    row_count: number;
    bulk_import_status: BulkImportStatus;
    file: string;
    note: string;
    name: string;
    bulk_import_created_at: string;
    bulk_import_updated_at: string;
}

export const bulkImportHeaders: HeadCell[] = [
    {
        id: 'bulk_import_id',
        numeric: false,
        disablePadding: false,
        label: 'Bulk Import ID',
        sortable: true,
    },
    {
        id: 'row_count',
        numeric: false,
        disablePadding: false,
        label: 'Review Count',
        sortable: true,
    },
    {
        id: 'bulk_import_status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: false,
    },
    {
        id: 'file',
        numeric: false,
        disablePadding: false,
        label: 'File Name',
        sortable: false,
    },
    {
        id: 'note',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        sortable: false,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Created by',
        sortable: true,
    },
    {
        id: 'bulk_import_created_at',
        numeric: false,
        disablePadding: false,
        label: 'Created',
        sortable: true,
    },
    {
        id: 'bulk_import_updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Update',
        sortable: true,
    },
    {
        id: 'action',
        bulkPermission: true,
        iconAction: <Icon.DownloadCloud height={24} width={24} />,
        iconNoAction: <Icon.X height={24} width={24} />,
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false,
    },
];

export const myTeamHeaders: HeadCell[] = [
    {
        id: 'agent_name',
        numeric: false,
        disablePadding: false,
        label: 'Agent',
        sortable: true,
    },
    {
        id: 'pending_reviews_count',
        numeric: false,
        disablePadding: false,
        label: 'Pending',
        sortable: true,
    },
    {
        id: 'response_required_count',
        numeric: false,
        disablePadding: false,
        label: 'Response Required',
        sortable: true,
    },
    {
        id: 'total_count',
        numeric: false,
        disablePadding: false,
        label: 'Total',
        sortable: true,
    },
    {
        id: 'oldest_assigned',
        numeric: false,
        disablePadding: false,
        label: 'Oldest Assigned',
        sortable: true,
    },
];

interface MyTeamTableObject {
    agent_name: string;
    pending_reviews_count: number;
    response_required_count: number;
    total_count: number;
    oldest_assigned: string;
}

export const mapTypeToTableHeader: { [key: string]: HeadCell[] } = {
    [QueueTypes.NEW]: newHeaders,
    [QueueTypes.RESPONSE_REQUIRED]: reviewTicketHeader,
    [QueueTypes.MINE]: myWorkHeaders,
    [QueueTypes.SEARCH]: searchHeaders,
    [QueueTypes.BULK_IMPORT]: bulkImportHeaders,
    [QueueTypes.MY_TEAM]: myTeamHeaders,
};

export type ReviewTableData = GetReviewsResponse | ReviewTicketResponse[] | GetBulkImports | GetTeamDetail;

export const mapRowByType = (
    type: QueueTypes,
    rowData: ReviewTableData
): ReviewTableObject[] | MyWorkTableObject[] | SearchTableObject[] | BulkImportTableObject[] | MyTeamTableObject[] => {
    switch (type) {
        case QueueTypes.NEW:
            return ((data: GetReviewsResponse): ReviewTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const review = _.find(
                        data.included,
                        (r) => r.type === 'review' && +r.attributes.survey_response_id === +attributes.survey_response_id
                    );
                    const unit = _.find(data.included, (u) => u.type === 'unit' && u.id === attributes.unit_id);
                    const reviewDate = (review as ReviewGetResponse)?.attributes.review_date;
                    return {
                        review_id: +review?.id!,
                        review_date: reviewDate ? format(new Date(reviewDate), 'yyyy-MM-dd') : '',
                        admin_unit_id: (unit as UnitResponse)?.attributes.admin_unit_id,
                        unit_code: (unit as UnitResponse)?.attributes.unit_code,
                        reservation_id: attributes.admin_reservation_id,
                        age: reviewDate ? formatDistance(new Date(reviewDate), new Date()) : '',
                        rating: (review as ReviewGetResponse)?.attributes.rating,
                    };
                });
            })(rowData as GetReviewsResponse);

        case QueueTypes.MINE:
            return ((data: GetReviewsResponse): MyWorkTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const review = _.find(
                        data.included,
                        (r) => r.type === 'review' && +r.attributes.survey_response_id === +attributes.survey_response_id
                    );
                    const unit = _.find(data.included, (u) => u.type === 'unit' && u.id === attributes.unit_id);
                    const assigned = _.find(data.included, (u) => u.type === 'user' && u.id === attributes.assigned_to_id);
                    const reviewDate = (review as ReviewGetResponse)?.attributes.review_date;
                    return {
                        review_id: +review?.id!,
                        review_date: reviewDate ? format(new Date(reviewDate), 'yyyy-MM-dd') : '',
                        admin_unit_id: (unit as UnitResponse)?.attributes.admin_unit_id,
                        unit_code: (unit as UnitResponse)?.attributes.unit_code,
                        reservation_id: attributes.admin_reservation_id,
                        age: reviewDate ? formatDistance(new Date(reviewDate), new Date()) : '',
                        rating: (review as ReviewGetResponse)?.attributes.rating,
                        assigned_to: (assigned as UserResponse)?.attributes.name ?? '-',
                        assigned_on: attributes.assigned_at ? format(new Date(attributes.assigned_at.toString()), 'yyyy-MM-dd') : '',
                    };
                });
            })(rowData as GetReviewsResponse);

        case QueueTypes.SEARCH:
            return ((data: GetReviewsResponse): SearchTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const review = _.find(
                        data.included,
                        (r) => r.type === 'review' && +r.attributes.survey_response_id === +attributes.survey_response_id
                    );
                    const unit = _.find(data.included, (u) => u.type === 'unit' && u.id === attributes.unit_id);
                    const assigned = _.find(data.included, (u) => u.type === 'user' && u.id === attributes.assigned_to_id);
                    const reviewDate = (review as ReviewGetResponse)?.attributes.review_date;
                    return {
                        review_id: review?.id!,
                        review_date: reviewDate ? format(new Date(reviewDate), 'yyyy-MM-dd') : '',
                        admin_unit_id: (unit as UnitResponse)?.attributes.admin_unit_id,
                        unit_code: (unit as UnitResponse)?.attributes.unit_code,
                        reservation_id: attributes.admin_reservation_id,
                        updated_at: attributes.updated_at ? format(new Date(attributes.updated_at), 'yyyy-MM-dd') : '',
                        rating: (review as ReviewGetResponse)?.attributes.rating,
                        assigned_to: (assigned as UserResponse)?.attributes.name ?? '-',
                        status: (review as ReviewGetResponse)?.attributes.status,
                    };
                });
            })(rowData as GetReviewsResponse);

        case QueueTypes.RESPONSE_REQUIRED:
            return ((data: GetReviewsResponse): ReviewTicketTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const review = _.find(
                        data.included,
                        (r) => r.type === 'review' && +r.attributes.survey_response_id === +attributes.survey_response_id
                    );
                    const unit = _.find(data.included, (u) => u.type === 'unit' && u.id === attributes.unit_id);
                    const assigned = _.find(data.included, (u) => u.type === 'user' && u.id === attributes.assigned_to_id);
                    const maintenanceTicket = _.find(
                        data.included,
                        (t) => t.type === 'maintenance-ticket' && t.attributes.survey_response_id === attributes.survey_response_id
                    );
                    const reviewData = (review as ReviewGetResponse)?.attributes;
                    const mtObject = (maintenanceTicket as MaintenanceTicketResponse)?.attributes;
                    return {
                        review_id: reviewData?.review_id ?? 0,
                        review_date: reviewData?.review_date ? format(new Date(reviewData.review_date), 'yyyy-MM-dd') : '',
                        admin_unit_id: (unit as UnitResponse)?.attributes.admin_unit_id,
                        unit_code: (unit as UnitResponse)?.attributes.unit_code ?? '',
                        reservation_id: attributes?.admin_reservation_id ?? '',
                        assigned_to: (assigned as UserResponse)?.attributes.name ?? '-',
                        ticket: mtObject?.admin_ticket_id ?? '',
                        lom_name: (unit as UnitResponse)?.attributes.unit_name,
                        update: mtObject?.updated_at ? format(new Date(mtObject.updated_at.toString()), 'yyyy-MM-dd') : '',
                        ticket_created_at: mtObject?.created_at ? format(new Date(mtObject.created_at.toString()), 'yyyy-MM-dd') : '',
                        follow_up: mtObject?.follow_up_date
                            ? formatDistance(new Date(mtObject.follow_up_date.toString()), new Date(), { addSuffix: true })
                            : '',
                        follow_up_date: mtObject?.follow_up_date ? format(new Date(mtObject.follow_up_date.toString()), 'yyyy-MM-dd') : '',
                        ticket_id: mtObject?.maintenance_ticket_id,
                        follow_up_status: mtObject?.follow_up_status,
                        rating: (review as ReviewGetResponse)?.attributes.rating,
                    };
                });
            })(rowData as GetReviewsResponse);

        case QueueTypes.BULK_IMPORT:
            return ((data: GetBulkImports): BulkImportTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const user = _.find(data.included, (u) => u.type === 'user' && u.id === attributes.created_by_id);
                    const create_at = attributes.created_at;
                    const updated_at = attributes.updated_at;
                    return {
                        bulk_import_id: attributes.bulk_import_id,
                        row_count: attributes.row_count,
                        bulk_import_status: attributes.status,
                        file: attributes.file.split('/')[1],
                        note: attributes.note,
                        name: (user as UserResponse).attributes.name,
                        bulk_import_created_at: format(new Date(create_at ?? null), 'yyyy-MM-dd'),
                        bulk_import_updated_at: format(new Date(updated_at ?? null), 'yyyy-MM-dd'),
                    };
                });
            })(rowData as GetBulkImports);

        case QueueTypes.MY_TEAM:
            return ((data: GetTeamDetail): MyTeamTableObject[] => {
                return _.map(data.data, ({ attributes }) => {
                    const oldest_assigned = attributes.oldest_assigned;
                    return {
                        agent_name: attributes.agent_name,
                        pending_reviews_count: +attributes.pending_reviews_count,
                        response_required_count: +attributes.response_required_count,
                        total_count: +attributes.total_count,
                        oldest_assigned: format(new Date(oldest_assigned ?? null), 'yyyy-MM-dd'),
                    };
                });
            })(rowData as GetTeamDetail);
    }
};

export const renderEmptyModal = (type: QueueTypes, buttonHandler?: () => any, autoAssignBtn?: boolean): any => {
    switch (type) {
        case QueueTypes.MY_TEAM:
        case QueueTypes.BULK_IMPORT:
        case QueueTypes.NEW:
            return (
                <div className={'empty-modal'}>
                    <span>Data not found</span>
                </div>
            );

        case QueueTypes.SEARCH:
            return (
                <div className={'empty-modal'}>
                    <span>Search not found</span>
                </div>
            );
        case QueueTypes.MINE:
            return (
                <div className={'auto-assign-modal '}>
                    <span>You have no pending reviews assigned.</span>
                    {autoAssignBtn && buttonHandler && (
                        <>
                            <span>Press the following button to auto assign yourself new reviews.</span>

                            <Button customClass={'m-4 btn-assign'} variant={'secondary'} onClick={buttonHandler}>
                                Fetch More Reviews
                            </Button>
                        </>
                    )}
                </div>
            );
        case QueueTypes.RESPONSE_REQUIRED:
            return (
                <div className={'empty-modal'}>
                    <span>You have no assigned response required reviews</span>
                </div>
            );
    }
};
