import { createEntityAdapter, createSlice, EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
    BaseUser,
    GetReviewsResponse,
    InitialCountBase,
    ReviewGetResponse,
    ReviewStatus,
    Roles,
    SurveyResponseResponse,
    SurveyType,
} from '@reviews/interfaces';
import * as _ from 'lodash';
import { QueueTypes } from '../components/ReviewsTable/WorkQueue.utils';
import { ApiRequest, reviewsApi, usersApi } from '../services';
import { getValidSearchParams, SearchTypes } from '../components/SearchReview/SearchReview.utils';
import { queueTypeToAppRoute } from '../Router';

export type SearchParams = {
    haveToLookFor: boolean;
    criteria: {
        value: SearchTypes;
        isValid: boolean;
    };
    searchValue: {
        value: { value: string; display: string } | string | number;
        isValid: boolean;
    };
};

export type FlowState = EntityState<SurveyResponseResponse> & {
    user: BaseUser | null;
    myWorkFilter: boolean;
    queue: {
        index: number;
        path: string;
        type: QueueTypes;
    };
    startFilter: { [key: number]: { active: boolean } };
    request: ApiRequest;
    searchParams: SearchParams;
    initialCountBase: InitialCountBase;
    curr: EntityId | null;
    next: EntityId | null;
};

export const filtersByQueueType: { [key: string]: { filters?: object; include?: string; sort?: string } } = {
    [QueueTypes.NEW]: {
        filters: { '[review.status]': ReviewStatus.NEW, '[processed]': false, '[type]': SurveyType.REVIEW },
        include: 'review,unit',
        sort: 'review.review_date,-review.review_id',
    },
    [QueueTypes.MINE]: {
        filters: { '[review.status]': ReviewStatus.PENDING, '[processed]': false, '[type]': SurveyType.REVIEW },
        include: 'review,unit,assigned_to',
    },
    [QueueTypes.RESPONSE_REQUIRED]: {
        filters: { '[review.status]': ReviewStatus.RESPONSE_REQUIRED, '[processed]': false, '[type]': SurveyType.REVIEW },
        include: 'review,unit,assigned_to,maintenance_ticket',
        sort: 'maintenance_ticket.follow_up_date',
    },
    [QueueTypes.SEARCH]: {
        filters: { '[type]': SurveyType.REVIEW },
        include: 'review,unit,assigned_to',
        sort: '-review.review_id',
    },
    [QueueTypes.BULK_IMPORT]: {},
    [QueueTypes.MY_TEAM]: {},
};

const flowAdapter = createEntityAdapter<SurveyResponseResponse>({
    selectId: (sr) => sr.id,
});

const defaultStartCount = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
};

const defaultSearchParams = {
    haveToLookFor: false,
    criteria: {
        value: '' as SearchTypes,
        isValid: false,
    },
    searchValue: {
        value: { value: '', display: '' },
        isValid: false,
    },
};

const initialState: FlowState = flowAdapter.getInitialState({
    user: null,
    myWorkFilter: true,
    queue: {
        type: QueueTypes.NEW,
        path: '/new',
        index: 0,
    },
    request: { ...filtersByQueueType[QueueTypes.SEARCH], pageNumber: 0, pageSize: 10 },
    startFilter: {
        1: { active: false },
        2: { active: false },
        3: { active: false },
        4: { active: false },
        5: { active: false },
    },
    searchParams: defaultSearchParams,
    initialCountBase: {
        new: 0,
        pending: 0,
        total: 0,
        assigned: 0,
        response_required: 0,
        pending_assigned: 0,
        response_required_assigned: 0,
        new_count_by_rating: defaultStartCount,
        pending_count_by_rating: defaultStartCount,
        assigned_count_by_rating: defaultStartCount,
    },
    curr: null,
    next: null,
});

const flowSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {
        setDataAndFilters: (state, action: PayloadAction<{ response: GetReviewsResponse; request: ApiRequest; queueType: QueueTypes }>) => {
            const ids: Array<number> = [];
            const reviews = _.filter(action.payload.response.included, (r) => r.type === 'review');
            _.forEach(reviews as Array<ReviewGetResponse>, (r) => {
                ids.push(r.id);
            });
            state.ids = ids;
            state.request = { ...state.request, ...action.payload.request };

            state.queue = {
                ...queueTypeToAppRoute(state.user?.role)[action.payload.queueType],
                type: action.payload.queueType,
            };

            if (action.payload.queueType === QueueTypes.SEARCH || action.payload.queueType === QueueTypes.BULK_IMPORT) {
                return;
            }

            const filters: any = action.payload.request.filters;

            if (_.isNil(filters['[review.rating][in]'])) {
                return;
            }

            _.forOwn(state.startFilter, (_value, key) => {
                state.startFilter[+key] = { active: key === filters['[review.rating][in]'] };
            });
        },
        setFiltersWhenChangePage: (state, action: PayloadAction<{ request: ApiRequest }>) => {
            state.request = action.payload.request;
        },
        setCurrent: (state, action: PayloadAction<{ review_id: string }>) => {
            const index = state.ids.indexOf(action.payload.review_id);
            state.curr = state.ids[index];
            state.next = index === state.ids.length ? null : state.ids[index + 1];
        },
        setInitFilters: (state, action: PayloadAction<{ queueType: QueueTypes; searchParams?: any }>) => {
            if (state.queue.type === action.payload.queueType) {
                return;
            }

            state.queue = {
                ...queueTypeToAppRoute(state.user?.role)[action.payload.queueType],
                type: action.payload.queueType,
            };

            let { filters, ...rest }: ApiRequest = filtersByQueueType[action.payload.queueType];

            if (state.myWorkFilter && (state.queue.type === QueueTypes.MINE || state.queue.type === QueueTypes.RESPONSE_REQUIRED)) {
                filters = {
                    ...filters,
                    '[assigned_to.email]': state.user?.email,
                };
            }

            if (
                !_.isNil(action.payload.searchParams) &&
                action.payload.searchParams.has('criteria') &&
                action.payload.searchParams.has('value')
            ) {
                const criteria = action.payload.searchParams.get('criteria');
                const value = action.payload.searchParams.get('value');

                state.searchParams = getValidSearchParams(criteria, value);

                if (state.searchParams.criteria.isValid && state.searchParams.searchValue.isValid) {
                    filters = { [`${state.searchParams.criteria.value}`]: state.searchParams.searchValue.value };
                }
            }

            state.request = { ...rest, filters, pageNumber: 0, pageSize: 10 };

            _.forOwn(state.startFilter, (_value, key) => {
                state.startFilter[+key] = { active: false };
            });
        },
        setMyWorkFilter: (state, action: PayloadAction<{ myWorkFilter: boolean }>) => {
            state.myWorkFilter = action.payload.myWorkFilter;
            let request: ApiRequest;
            if (state.myWorkFilter && (state.queue.type === QueueTypes.MINE || state.queue.type === QueueTypes.RESPONSE_REQUIRED)) {
                request = {
                    ...state.request,
                    filters: {
                        ...filtersByQueueType[state.queue.type].filters,
                        '[assigned_to.email]': state.user?.email,
                    },
                };
            } else {
                request = { ...filtersByQueueType[state.queue.type] };
            }
            state.request = {
                ...request,
                pageNumber: 0,
                pageSize: 10,
            };
            _.forOwn(state.startFilter, (_value, key) => {
                state.startFilter[+key] = { active: false };
            });
        },
        setSearchDone: (
            state,
            action: PayloadAction<{ criteria: SearchTypes; value: { display: string; value: string } | number | string }>
        ) => {
            state.searchParams = {
                haveToLookFor: false,
                criteria: {
                    value: action.payload.criteria,
                    isValid: true,
                },
                searchValue: {
                    value: action.payload.value,
                    isValid: true,
                },
            };
        },
        setEmptySearchParams: (state, action: PayloadAction<{ queueType: QueueTypes }>) => {
            if (action.payload.queueType !== QueueTypes.SEARCH) {
                state.searchParams = defaultSearchParams;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(reviewsApi.endpoints.getInitialCount.matchFulfilled, (state, { payload }) => {
            const { attributes } = payload.data;
            state.initialCountBase = {
                new: attributes.new ?? 0,
                pending: attributes.pending ?? 0,
                total: attributes.total ?? 0,
                assigned: attributes.assigned ?? 0,
                response_required: attributes.response_required ?? 0,
                pending_assigned: attributes.pending_assigned ?? 0,
                response_required_assigned: attributes.response_required_assigned ?? 0,
                new_count_by_rating: {
                    1: attributes.new_count_by_rating['1'] ?? 0,
                    2: attributes.new_count_by_rating['2'] ?? 0,
                    3: attributes.new_count_by_rating['3'] ?? 0,
                    4: attributes.new_count_by_rating['4'] ?? 0,
                    5: attributes.new_count_by_rating['5'] ?? 0,
                },
                pending_count_by_rating: {
                    1: attributes.pending_count_by_rating['1'] ?? 0,
                    2: attributes.pending_count_by_rating['2'] ?? 0,
                    3: attributes.pending_count_by_rating['3'] ?? 0,
                    4: attributes.pending_count_by_rating['4'] ?? 0,
                    5: attributes.pending_count_by_rating['5'] ?? 0,
                },
                assigned_count_by_rating: {
                    1: attributes.assigned_count_by_rating['1'] ?? 0,
                    2: attributes.assigned_count_by_rating['2'] ?? 0,
                    3: attributes.assigned_count_by_rating['3'] ?? 0,
                    4: attributes.assigned_count_by_rating['4'] ?? 0,
                    5: attributes.assigned_count_by_rating['5'] ?? 0,
                },
            };
        });
        builder.addMatcher(usersApi.endpoints.getUserRole.matchFulfilled, (state, { payload }) => {
            const user = payload.data.attributes;
            if (_.isNil(user)) {
                return;
            }
            state.user = user;

            switch (user.role) {
                case Roles.AGENT:
                    state.queue = {
                        ...queueTypeToAppRoute()[QueueTypes.MINE],
                        type: QueueTypes.MINE,
                    };
                    state.request = {
                        ...filtersByQueueType[state.queue.type],
                        filters: { ...filtersByQueueType[state.queue.type].filters, '[assigned_to.email]': state.user?.email },
                        pageNumber: 0,
                        pageSize: 10,
                    };
                    break;
                case Roles.EMPLOYEE:
                    state.queue = {
                        ...queueTypeToAppRoute()[QueueTypes.SEARCH],
                        type: QueueTypes.SEARCH,
                        index: 0,
                    };
                    state.request = {
                        ...filtersByQueueType[state.queue.type],
                        pageNumber: 0,
                        pageSize: 10,
                    };
                    break;
                case Roles.TEAM_LEAD:
                case Roles.ADMIN:
                    state.queue = {
                        ...queueTypeToAppRoute()[QueueTypes.NEW],
                        type: QueueTypes.NEW,
                    };
                    state.request = {
                        ...filtersByQueueType[state.queue.type],
                        pageNumber: 0,
                        pageSize: 10,
                    };
                    break;
            }
        });
    },
});

export const {
    setCurrent,
    setDataAndFilters,
    setMyWorkFilter,
    setInitFilters,
    setFiltersWhenChangePage,
    setSearchDone,
    setEmptySearchParams,
} = flowSlice.actions;

export default flowSlice.reducer;
