import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import './TextBox.scss';
import { EditorMode } from './EditorMode';
import * as _ from 'lodash';

interface TextBoxProps {
    title?: string | null;
    text?: string | number | null;
    onEdit?: (newText: string, oldText: string, attribute: string) => void;
    onHide?: () => void;
    maxLength?: number;
    style?: string;
    surveyId?: any;
}

export const TextBox: React.FC<TextBoxProps> = (props: TextBoxProps) => {
    let { title, text, onEdit, maxLength, style, surveyId } = props;
    const [isShowingCollapsedText, setIsShowingCollapsedText] = useState<boolean>(false);
    const [isTitleEdit, setTitleEdit] = useState<boolean>(false);
    const [isTextEdit, setTextEdit] = useState<boolean>(false);

    useEffect(() => {
        setTextEdit(false);
        setTitleEdit(false);
    }, [surveyId]);

    if (!text) {
        return null;
    }

    if (_.isNumber(text)) {
        text = String(text);
    }

    const collapseAt = maxLength || Number.MAX_SAFE_INTEGER;

    const shouldCollapse = text.length > collapseAt;
    const trimmedText = text.substring(0, collapseAt);
    const remainingText = text.substring(collapseAt, text.length);

    const RenderTitle: React.FC = () => {
        return isTitleEdit && onEdit ? (
            <EditorMode onEdit={(a, b, c) => onEdit?.(a, b, c)} value={title!} setValueEdit={setTitleEdit} isTitle={true} />
        ) : (
            <p
                onDoubleClick={() => {
                    setTitleEdit(true);
                }}
            >
                {title}
            </p>
        );
    };
    const renderWithLineBreak = (text: string) => (text ? text.replace(/<br\/>|<br \/>/g, '\n') : '');
    return (
        <div className="text-box">
            {title && (
                <div className={`body-small-bold text-box-actions ${style}`}>
                    <RenderTitle />
                </div>
            )}
            <div className="text-box-actions">
                {isTextEdit && onEdit && text ? (
                    <EditorMode onEdit={(a, b, c) => onEdit?.(a, b, c)} value={text} setValueEdit={setTextEdit} isTitle={false} />
                ) : (
                    <div
                        onDoubleClick={() => {
                            setTextEdit(true);
                        }}
                    >
                        <p className={`box-text ${style}`}>
                            {renderWithLineBreak(trimmedText)}
                            <Collapse in={isShowingCollapsedText}>
                                <span>{renderWithLineBreak(remainingText)}</span>
                            </Collapse>
                            {shouldCollapse && (
                                <span className="collapsed-text-action" onClick={() => setIsShowingCollapsedText(!isShowingCollapsedText)}>
                                    {isShowingCollapsedText ? ' ...less' : ' ...more'}
                                </span>
                            )}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};
