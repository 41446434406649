import React from 'react';
import { Icon, withTooltip } from '@vacasa/react-components-lib';
import { Icons } from '../../assests/icons';
interface ReviewDetailAnswerListToolbar {
    onClickAction: (tag: ElementTag) => void;
    selectedAction: { [key: string]: boolean };
    hideTickectAction: boolean;
    className?: string;
}
export enum ElementTag {
    HistoryView = 'HISTORY_VIEW',
    ProcessReview = 'PROCESS_REVIEW',
    MaintenanceTicketView = 'MAINTENANCE_TICKET_VIEW',
}

export const ReviewDetailAnswerListToolbar: React.FC<ReviewDetailAnswerListToolbar> = (props: ReviewDetailAnswerListToolbar) => {
    const { onClickAction, className, selectedAction, hideTickectAction } = props;

    return (
        <div className={`review-detail-icon-toolbar ${className ?? ''}`}>
            {withTooltip(
                'Show Review History',
                <span className={selectedAction[ElementTag.HistoryView] ? 'selected-action' : ''}>
                    <Icons.History id="review-detail-history" onClick={() => onClickAction(ElementTag.HistoryView)} />
                </span>
            )}

            {!hideTickectAction &&
                withTooltip(
                    'Add Maintenance Ticket',
                    <span className={selectedAction[ElementTag.MaintenanceTicketView] ? 'selected-action' : ''}>
                        <Icon.Tag id="review-detail-tickets" onClick={() => onClickAction(ElementTag.MaintenanceTicketView)} />
                    </span>
                )}

            {withTooltip(
                'Process Review',
                <span className={selectedAction[ElementTag.ProcessReview] ? 'selected-action' : ''}>
                    <Icons.Process onClick={() => onClickAction(ElementTag.ProcessReview)} />
                </span>
            )}
        </div>
    );
};
