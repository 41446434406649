import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetReviewDetailsQuery,
    useGetTicketDetailQuery,
    useLazyGet90DayAvgQuery,
    useReopenReviewMutation,
    useUpdateSurveyResponseAnswerMutation,
} from '../../services';
import { Icons } from '../../assests/icons';
import { GoBack, ReviewDetailAnswerList, ReviewDetailOverview } from '../../components';
import './ReviewDetail.scss';
import { SurveyResponseAnswers } from '@reviews/interfaces';
import * as _ from 'lodash';
import { Alert } from '@mui/material';
import ReOpenReviewModal from '../../components/ReOpenReviewModal/ReOpenReviewModal';
import RefreshModal from '../../components/RefreshModal/RefreshModal';
import { AssignModal } from '../../components/AssignModal/AssignModal';
import { getDiffTimeInSeg } from '../../utils/shared.utils';
import { useLoading } from '../../contexts/LoadingContext';

export function ReviewDetail() {
    const params = useParams();
    const { data, error, isLoading, isFetching, refetch } = useGetReviewDetailsQuery(+params.id!, { refetchOnMountOrArgChange: true });
    const { setLoadingConfig } = useLoading();
    const [trigger] = useLazyGet90DayAvgQuery();
    const [unitAvgByQuestionCategory, setUnitAvgByQuestionCategory] = useState<{ [category: string]: number }>({});
    const ticketData = useGetTicketDetailQuery(+params.id!);
    const [startTime, setStartTime] = useState<number>(Date.now());

    const [isLoadingAvg, setIsLoadingAvg] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            if (!_.isNil(data)) {
                const {
                    data: { attributes },
                } = data;
                const { data: ninetyDayAvg } = await trigger({ admin_unit_id: attributes.admin_unit_id });
                setLoadingConfig(false);
                const obj: { [category: string]: number } = {};
                if (!_.isNil(ninetyDayAvg)) {
                    for (const value of _.keys(ninetyDayAvg.data.attributes)) {
                        const categoryName = (ninetyDayAvg.data.attributes as { [key: string]: any })[value].name;
                        obj[`${categoryName}`] = (ninetyDayAvg.data.attributes as { [key: string]: any })[value].avg;
                    }
                }
                setUnitAvgByQuestionCategory(obj);
                setIsLoadingAvg(false);
            }
        })();
    }, [data]);

    useEffect(() => {
        setLoadingConfig(true);
        setIsLoadingAvg(true);
    }, [params]);

    const [updateSurveyResponse, { error: errorUpdate }] = useUpdateSurveyResponseAnswerMutation();
    const [reopenReview, { isLoading: isLoadingReopen }] = useReopenReviewMutation();
    const [errorReopenReview, setErrorReopenReview] = useState(false);

    const [showOpenModal, setShowOpenModal] = useState<boolean>(false);
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const handleReviewResponseSave = (response: string) => {
        alert(`response: ${response}`);
    };

    const handleAnswerEdit = async (
        survey_response_answer: SurveyResponseAnswers,
        new_value?: string | number,
        old_value?: string | number,
        attribute?: string
    ) => {
        if (survey_response_answer.answer.data) {
            const answer = {
                data: {
                    ...survey_response_answer.answer?.data,
                    [attribute as string]: _.toString(new_value),
                },
                dismissed: survey_response_answer.answer.dismissed,
            };
            const id = survey_response_answer.survey_response_id;
            const survey_response_answers_id = _.toNumber(survey_response_answer.survey_response_answers_id);
            setLoadingConfig(true, false);
            await updateSurveyResponse({ id: Number(id), survey_response_answers_id, new_value, old_value, attribute, answer }).unwrap();
            setLoadingConfig(false);
        }
    };

    const handleReopenReview = async (surveyResponseId: number) => {
        setLoadingConfig(true, false);
        setErrorReopenReview(false);
        try {
            await reopenReview({
                survey_response_id: surveyResponseId,
                time: getDiffTimeInSeg(Date.now(), startTime),
            }).unwrap();

            setShowOpenModal(false);
            setStartTime(Date.now());
        } catch (e) {
            setErrorReopenReview(true);
        } finally {
            setLoadingConfig(false);
        }
    };

    const handleRefreshModal = () => {
        refetch();
        ticketData.refetch();
        setShowRefreshModal(false);
        setShowAssignModal(false);
        setShowOpenModal(false);
    };

    const handleShowReopenModal = () => {
        setErrorReopenReview(false);
        setShowOpenModal(true);
    };

    if (isLoading) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <h1>An error occurred:</h1>
                <div>{JSON.stringify(error)}</div>
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const {
        data: { attributes },
    } = data;

    const currentAssignUser = attributes?.assigned_to;

    return (
        <React.Fragment>
            {errorUpdate && <Alert severity="error">Error updating enter valid characters</Alert>}
            <div className="card review-detail">
                <div className={'navigation'}>
                    <GoBack setStartTime={setStartTime} />
                </div>
                <div className="card-body">
                    <ReviewDetailOverview review={attributes} onOpenAssignModal={() => setShowAssignModal(true)} />
                    <ReviewDetailAnswerList
                        review={attributes}
                        surveyHistory={attributes.survey_history}
                        onOpenReviewModal={handleShowReopenModal}
                        onOpenRefreshModal={() => setShowRefreshModal(true)}
                        isLoadingReview={isFetching}
                        isLoadingAvg={isLoadingAvg}
                        onResponseSave={handleReviewResponseSave}
                        onAnswerEdit={handleAnswerEdit}
                        ticketData={ticketData}
                        unitAvgByQuestionCategory={unitAvgByQuestionCategory}
                        startTime={startTime}
                        setStartTime={setStartTime}
                    />
                </div>
            </div>
            {showOpenModal && (
                <div>
                    <ReOpenReviewModal
                        showModal={showOpenModal}
                        onHide={() => setShowOpenModal(false)}
                        handleAction={handleReopenReview}
                        handleOnRefresh={handleRefreshModal}
                        data={attributes}
                        isLoading={isLoadingReopen}
                        error={errorReopenReview}
                    />
                </div>
            )}
            {showAssignModal && (
                <AssignModal
                    onHide={() => setShowAssignModal(false)}
                    showModal={showAssignModal}
                    onRefreshPage={handleRefreshModal}
                    assignUser={currentAssignUser}
                    reviewId={attributes.review_id}
                />
            )}

            {showRefreshModal && (
                <RefreshModal
                    showModal={showRefreshModal}
                    onHide={() => setShowRefreshModal(false)}
                    handleAction={handleRefreshModal}
                    isLoading={isFetching}
                />
            )}
        </React.Fragment>
    );
}
