import React from 'react';
import { DetailedReview, mapReviewStatusToString, Roles } from '@reviews/interfaces';
import { addDays, format } from 'date-fns';
import './ReviewDetailOverview.scss';
import { isProcessedReview, openInNewTab } from '../../utils/shared.utils';
import { Icon } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { AppRoutes } from '../../Router';
import { Configuration } from '../../Configuration';
import { SearchCriteriaParam } from '../SearchReview/SearchReview.utils';
import { useSelector } from 'react-redux';
import { Store } from '../../store';
import { SummaryContainer } from '../SummaryContainer/SummaryContainer';

interface ReviewDetailOverviewProps {
    review: DetailedReview;
    onOpenAssignModal: () => void;
}

export const ReviewDetailOverview: React.FC<ReviewDetailOverviewProps> = (props: ReviewDetailOverviewProps) => {
    const {
        review: {
            review_id,
            review_date,
            unit_code,
            status,
            assigned_to,
            admin_unit_id,
            admin_reservation_id,
            first_name,
            last_name,
            email,
            unit_name,
            reservation,
            review_summary_data,
        },
        onOpenAssignModal,
    } = props;

    const last_night = reservation?.last_night ?? '';
    const lastNightDate = new Date(last_night);
    const checkOutDate = addDays(lastNightDate, 1);

    const flow = useSelector((state: Store) => state.flow);
    const isEmployee = flow.user?.role === Roles.EMPLOYEE;

    const isNilReservation = _.isNil(admin_reservation_id) || _.isNil(reservation);

    const pathWithCriteria = AppRoutes.SEARCH_WITH_PARAMS.replace(':criteria', SearchCriteriaParam.ADMIN_UNIT_ID);
    const pathWithValue = pathWithCriteria.replace(':value', _.toString(admin_unit_id));

    const isAssignable = !isProcessedReview(status);

    return (
        <React.Fragment>
            <div className="review-detail-banner">
                <h4 className={'heading-card-24'}>{`Review ID: ${review_id}`}</h4>
                <div>
                    <span className={'body-regular d-flex'}>
                        <span className={'body-bold ms-1'}>Status:</span> <span className={'ms-1'}>{mapReviewStatusToString[status]}</span>
                        <span className={'ms-1'}> | </span>
                        <span className={'body-bold ms-1'}>Assigned:</span>
                        {isAssignable && !isEmployee ? (
                            <span onClick={onOpenAssignModal} className={'link-style'}>
                                {assigned_to?.name ?? 'not assigned'}
                            </span>
                        ) : (
                            <span>{assigned_to?.name ?? 'not assigned'}</span>
                        )}
                    </span>
                </div>
            </div>

            <div className="review-detail-information body-regular ">
                <div className="card review-detail-unit-information ">
                    <div className="card-body">
                        <div className={'d-flex'}>
                            <span className={'body-bold'}>Unit ID: </span>

                            <span
                                onClick={() => openInNewTab(`units/edit?UnitID=${admin_unit_id}`, Configuration.getVacasaAdminUrl())}
                                className={'link-style'}
                            >
                                {` ${admin_unit_id}`}
                                <Icon.ExternalLink width={20} />
                            </span>
                        </div>
                        <div>
                            <span className={'body-bold'}>Unit Code:</span> {unit_code}
                        </div>
                        <div>
                            <span className={'body-bold'}>Unit Name: </span> {unit_name}
                        </div>
                        <div>
                            <a href={Configuration.getReviewsManagementUrl().concat(pathWithValue)} className={'link-style'}>
                                See all Unit Reviews
                            </a>
                        </div>
                    </div>
                </div>

                <div className="card body-regular review-detail-reservation-information">
                    <div className="card-body">
                        <div className={'d-flex'}>
                            <span className={'d-flex'}>
                                <span className={'body-bold'}>Reservation ID: </span>

                                {isNilReservation ? (
                                    <span className={'ms-2'}>N/A</span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            openInNewTab(`reservation/${admin_reservation_id}`, Configuration.getVacasaAdminUrl())
                                        }
                                        className={'link-style'}
                                    >
                                        {`${admin_reservation_id}`}
                                        <Icon.ExternalLink width={20} />
                                    </span>
                                )}
                            </span>
                            <div className={'ms-2 me-2'}>|</div>
                            <span className={''}>
                                <span className={'body-bold'}>Check-Out Date: </span>
                                {!isNilReservation ? format(checkOutDate, 'yyyy-MM-dd') : ''}
                            </span>
                        </div>

                        <div>
                            <span>
                                <span className={'body-bold'}>Reservation Guest:</span>
                            </span>

                            <span>
                                <span className={'text-name'}>
                                    {!isNilReservation && reservation && ` ${reservation?.first_name} ${reservation?.last_name}`}
                                </span>
                                <span> | </span>
                                <span>
                                    <span className={'body-bold'}>Email: </span>
                                    <span>{!isNilReservation && reservation && ` ${reservation?.email}`} </span>
                                </span>
                            </span>
                        </div>
                        <div>
                            <span>
                                <span className={'body-bold'}>Reviewed By: </span>
                            </span>

                            <span>
                                <span className={'text-name'}>{`${first_name} ${last_name}`}</span>
                                <span> | </span>
                                <span>
                                    <span className={'body-bold'}>Email: </span>
                                    <span>{` ${email}`} </span>
                                </span>
                            </span>
                        </div>
                        <div>
                            <span>
                                <span className={'body-bold'}>Reviewed On: </span> {format(new Date(review_date), 'yyyy-MM-dd')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SummaryContainer reviewSummaryData={review_summary_data} />
            </div>
        </React.Fragment>
    );
};
