import { EventTimeLine } from '../TimeLine/HistoryTimeLine';
import { HistoryAction, HistoryPayload, HistoryPayloadUser, SurveyHistory } from '@reviews/interfaces';
import { Icon } from '@vacasa/react-components-lib';
import { format } from 'date-fns';
import _ from 'lodash';

const ICON_WIDTH = 25;
const getHistoryAttributes = (action: HistoryAction, data: HistoryPayload): [HistoryPayload, string, string, any, HistoryAction] => {
    let actionString = '';
    let actionBoldString = '';
    let icon = <Icon.Circle width={ICON_WIDTH} />;

    switch (action) {
        case HistoryAction.EDITED:
            actionBoldString = `Review updated`;
            icon = <Icon.Edit width={ICON_WIDTH} />;
            break;

        case HistoryAction.CREATED:
            actionBoldString = `Review created`;
            icon = <Icon.Check width={ICON_WIDTH} />;
            break;

        case HistoryAction.PUBLISHED:
            actionBoldString = 'Review published';
            icon = <Icon.ThumbsUp width={ICON_WIDTH} />;
            break;

        case HistoryAction.SUPPRESSED:
            actionBoldString = 'Review suppressed';
            icon = <Icon.ThumbsDown width={ICON_WIDTH} />;
            break;
        case HistoryAction.ASSIGNED:
            const userData = data?.new as HistoryPayloadUser;
            actionBoldString = userData ? 'Review assigned to ' + userData.email : 'Review assigned';
            icon = <Icon.UserCheck width={ICON_WIDTH} />;
            break;

        case HistoryAction.NOTE:
            actionBoldString = 'Note Added';
            actionString = data?.note ?? '';
            icon = <Icon.FileText width={ICON_WIDTH} />;
            break;

        case HistoryAction.FOLLOW_UP:
            actionBoldString = 'Follow Up Added';
            actionString = data?.note ?? '';
            icon = <Icon.AlertCircle width={ICON_WIDTH} />;
            break;

        case HistoryAction.RESPONSE_REQUIRED:
            actionBoldString = 'Response Required Status';
            icon = <Icon.Tag width={ICON_WIDTH} />;
            break;

        case HistoryAction.TICKET_CREATED:
            actionBoldString = 'Maintenance Ticket Created';
            icon = <Icon.Tag width={ICON_WIDTH} />;
            break;

        case HistoryAction.REOPEN:
            actionBoldString = 'Reopen Review';
            icon = <Icon.Lock width={ICON_WIDTH} />;
            break;
        default:
            break;
    }

    return [data, actionString, actionBoldString, icon, action];
};

export const mapHistoryToTimeLine = (surveyHistory: Array<SurveyHistory>): Array<EventTimeLine> => {
    let eventHistoryArray = [];
    let sortedHistory = _.orderBy(surveyHistory, (s) => s.date, ['desc']);
    for (const history of sortedHistory) {
        let dateHistory = 'at ' + format(new Date(history.date), 'yyyy-MM-dd HH:mm') + ' by ';
        dateHistory += history.created_by.name;
        let [payload, titleHistory, titleBoldHistory, selectedIcon, action] = getHistoryAttributes(history.action_type, history.data);
        let newEvent: EventTimeLine = {
            title: titleHistory,
            titleBold: titleBoldHistory,
            subtitle: dateHistory,
            icon: selectedIcon,
            payload,
            action,
        };
        eventHistoryArray.push(newEvent);
    }

    return eventHistoryArray;
};
