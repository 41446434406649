import React, { createContext, useState, useContext, ReactNode } from 'react';

interface Config {
    loading: boolean;
    spinner: boolean;
}
interface LoadingContextProps {
    loadingConfig: Config;
    setLoadingConfig: (loading: boolean, spinner?: boolean) => void;
}

const initialState = {
    loading: false,
    spinner: true,
};

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [loadingConfig, setLoadingConfig] = useState<Config>(initialState);

    const customLoadingConfig = (loading: boolean, spinner: boolean = true) => {
        // To keep the spinner as the default view, this auxiliary method was created.
        // Every time the parameter loading false is passed to it, the value of the spinner is reset to true.
        setLoadingConfig({ loading, spinner: !loading ? true : spinner });
    };

    return <LoadingContext.Provider value={{ loadingConfig, setLoadingConfig: customLoadingConfig }}>{children}</LoadingContext.Provider>;
};

export const useLoading = (): LoadingContextProps => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};
