export const getDefaultValue = (
    showRating: boolean,
    showReview: boolean,
    initResponse: { response: string; display_rating_notes: string; display_review_notes: string },
    isClose: boolean,
    emptyReview: boolean,
    defaultSuppress: boolean
): {
    initShowRating: boolean;
    initShowReview: boolean;
    initResponseData: string;
} => {
    let initResponseText = '';

    const isPublished = showRating && showReview;
    const isPublishedWithoutReview = !showReview && showRating;
    const isSuppressed = !showReview && !showRating;

    if (isPublished) initResponseText = initResponse.response || '';

    if (isPublishedWithoutReview) initResponseText = initResponse.display_review_notes || '';

    if (isSuppressed) initResponseText = initResponse.display_rating_notes || '';

    let processReviewObj = {
        initShowRating: true,
        initShowReview: true,
        initResponseData: initResponseText,
    };

    if (isClose) {
        return {
            initShowRating: showRating,
            initShowReview: showReview,
            initResponseData: initResponseText,
        };
    }

    if (defaultSuppress) {
        return {
            initShowRating: false,
            initShowReview: false,
            initResponseData: 'The review is suppressed because it has no rating.',
        };
    }

    if (emptyReview) {
        return {
            initShowRating: true,
            initShowReview: false,
            initResponseData: 'Review with empty comment.',
        };
    }

    return processReviewObj;
};
