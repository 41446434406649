import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Configuration } from '../Configuration';
import * as _ from 'lodash';
import {
    GetReviewsResponse,
    ReviewDetailResponse,
    CreateNoteResponse,
    UpdateSurveyResponseAnswerRequest,
    MaintenanceTicketResponse,
    GetReviewTicketsResponse,
    SurveyType,
    InitialCountResponse,
    MaintenanceTicketDetailResponse,
    ReviewsBulkResponse,
    ReviewsBulk,
    GetBulkImports,
    GetS3AccessResponse,
    GetTeamDetail,
    NinetyDayAvgResponse,
    AssignResponse,
} from '@reviews/interfaces';
import { flowId, StorageKeys } from '@reviews/frontend';
import type {
    AddFollowUpQueryArg,
    AddHistoryQueryArg,
    AssignUserQueryArg,
    CreateReviewMaintenanceTicketQueryArg,
    EmptyQueryArg,
    Get90DayAvgQueryArg,
    GetInitialCountQueryArg,
    GetS3AccessQueryArg,
    ProcessReviewQueryArg,
    RefreshMaintenanceTicketQueryArg,
    UpdateSurveyResponseAnswerQueryArg,
} from '../types/review-service.type';
import { ReopenReviewQueryArg } from '../types/review-service.type';

export type ApiRequest = { pageNumber?: number; pageSize?: number; filters?: {}; include?: string; sort?: string };

export enum ResponseType {
    DETAILS = 'DETAILS',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    LIST = 'LIST',
}

export const reviewsApi = createApi({
    reducerPath: 'reviewsApi',
    tagTypes: ['Reviews', 'ReviewsDetails', 'InitialCount', 'Search', 'MaintenanceTicketDetail', 'BulkImport', 'TeamDetailCount'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${Configuration.getReviewsManagementAPIUrl()}/management/v1`,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json; charset=UTF-8');
            headers.set('Authorization', `Bearer ${localStorage.getItem(StorageKeys.ACCESS_TOKEN)}`);
            headers.set('x-identity-token', `${localStorage.getItem(StorageKeys.ID_TOKEN)}`);
            headers.set('Access-Control-Allow-Origin', `origin-list`);
            headers.set('X-Flow-Id', flowId);
            headers.set('X-Client-Identifier', 'reviews-management-app');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getReviews: builder.query<GetReviewsResponse, ApiRequest>({
            query: (req: ApiRequest) => {
                const filters = _.reduce(
                    req.filters,
                    (acc, curr, key) => {
                        acc[`filter${key}`] = curr;
                        return acc;
                    },
                    {} as { [key: string]: any }
                );
                const params = {
                    type: SurveyType.REVIEW,
                    'page[number]': (req.pageNumber && req.pageNumber + 1) || 1,
                    'page[size]': req.pageSize || 10,
                    include: req.include,
                    sort: req.sort,
                    'filter[type]': SurveyType.REVIEW,
                    ...filters,
                };
                return {
                    url: 'reviews/responses',
                    method: 'GET',
                    params,
                };
            },
            providesTags: [{ type: 'Reviews', id: ResponseType.LIST }],
        }),

        getBulkImports: builder.query<GetBulkImports, ApiRequest>({
            query: (req: ApiRequest) => {
                const filters = _.reduce(
                    req.filters,
                    (acc, curr, key) => {
                        acc[`filter[${key}]`] = curr;
                        return acc;
                    },
                    {} as { [key: string]: any }
                );
                const params = {
                    'page[number]': (req.pageNumber && req.pageNumber + 1) || 1,
                    'page[size]': req.pageSize || 10,
                    include: req.include,
                    sort: req.sort,
                    ...filters,
                };
                return {
                    url: 'reviews/bulk/imports',
                    method: 'GET',
                    params,
                };
            },
            providesTags: ['BulkImport'],
        }),

        getAllTicketReviews: builder.query<GetReviewTicketsResponse, EmptyQueryArg>({
            query: (_req) => {
                return {
                    url: 'reviews/tickets',
                    method: 'GET',
                };
            },
        }),

        getReviewDetails: builder.query<{ data: ReviewDetailResponse }, number>({
            query: (id: number) => ({
                url: `reviews/${id}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'ReviewsDetails', id: ResponseType.DETAILS }],
        }),

        getTicketDetail: builder.query<MaintenanceTicketDetailResponse, number>({
            query: (id: number) => ({
                url: `reviews/ticket`,
                method: 'GET',
                params: {
                    'filter[review_id]': id,
                    include: 'created_by',
                },
            }),
            providesTags: [{ type: 'MaintenanceTicketDetail', id: ResponseType.DETAILS }],
        }),

        getInitialCount: builder.query<{ data: InitialCountResponse }, GetInitialCountQueryArg>({
            query: (req: { email: string }) => ({
                url: 'reviews/counts',
                method: 'GET',
                params: { 'filter[email]': req.email },
            }),
            providesTags: [
                { type: 'InitialCount', id: ResponseType.SUCCESS },
                { type: 'InitialCount', id: ResponseType.LIST },
            ],
        }),

        get90DayAvg: builder.query<{ data: NinetyDayAvgResponse }, Get90DayAvgQueryArg>({
            query: (req: { admin_unit_id: number }) => ({
                url: 'reviews/ninety-day-avg',
                method: 'GET',
                params: { 'filter[admin_unit_id]': req.admin_unit_id },
            }),
        }),

        addHistoryNote: builder.mutation<{ data: CreateNoteResponse }, AddHistoryQueryArg>({
            query: ({ note, id }) => ({
                url: `reviews/${id}/history/note`,
                method: 'POST',
                body: {
                    data: {
                        type: 'create-history-note',
                        attributes: {
                            note,
                        },
                    },
                },
            }),
            invalidatesTags: ['ReviewsDetails'],
        }),

        processReview: builder.mutation<any, ProcessReviewQueryArg>({
            query: (req) => ({
                url: `reviews/${req.id}/process`,
                method: 'PUT',
                body: {
                    data: {
                        type: 'process-review',
                        attributes: {
                            show_rating: req.show_rating,
                            show_review: req.show_review,
                            response: req.response ?? '',
                            validate_assigned: req.validate_assigned,
                            time: req.time,
                        },
                    },
                },
            }),
            invalidatesTags: (_result, error) => [{ type: 'ReviewsDetails', id: error ? ResponseType.ERROR : ResponseType.DETAILS }],
        }),

        updateSurveyResponseAnswer: builder.mutation<{ data: UpdateSurveyResponseAnswerRequest }, UpdateSurveyResponseAnswerQueryArg>({
            query: (req) => ({
                url: `reviews/response/${req.id}`,
                method: 'PUT',
                body: {
                    data: {
                        type: 'survey-response-answer',
                        attributes: {
                            survey_response_answers_id: req.survey_response_answers_id,
                            new_value: req.new_value,
                            old_value: req.old_value,
                            answer: req.answer,
                            attribute: req.attribute,
                        },
                    },
                },
            }),
            invalidatesTags: ['ReviewsDetails'],
        }),

        createReviewMaintenanceTicket: builder.mutation<{ data: MaintenanceTicketResponse }, CreateReviewMaintenanceTicketQueryArg>({
            query: (req) => ({
                url: `reviews/${req.id}/maintenance-ticket`,
                method: 'POST',
                body: {
                    data: {
                        type: 'maintenance-ticket',
                        attributes: {
                            ...req.data,
                            validate_assigned: req.validate_assigned,
                            time: req.time,
                        },
                    },
                },
            }),
            invalidatesTags: (_result, error) => [
                { type: 'ReviewsDetails', id: error ? ResponseType.ERROR : ResponseType.DETAILS },
                { type: 'MaintenanceTicketDetail', id: error ? ResponseType.ERROR : ResponseType.DETAILS },
            ],
        }),

        refreshMaintenanceTicket: builder.mutation<any, RefreshMaintenanceTicketQueryArg>({
            query: (req) => ({
                url: `reviews/ticket/${req.id}/refresh`,
                method: 'PATCH',
                body: {
                    data: {
                        type: 'ticket-refresh',
                        attributes: {},
                    },
                },
            }),
            invalidatesTags: ['MaintenanceTicketDetail'],
        }),
        uploadBulkReview: builder.mutation<{ data: ReviewsBulkResponse }, ReviewsBulk>({
            query: (req: ReviewsBulk) => ({
                url: `/reviews/bulk/imports`,
                method: 'POST',
                body: {
                    data: {
                        type: 'reviews-bulk',
                        attributes: {
                            email: req.email,
                            file: req.file,
                            note: req.note,
                        },
                    },
                },
            }),
            invalidatesTags: ['BulkImport'],
        }),

        assignUser: builder.mutation<{ data: AssignResponse }, AssignUserQueryArg>({
            query: ({ idUser, idsReviews, validation }) => ({
                url: `users/${idUser}/assign/reviews`,
                method: `PATCH`,
                body: {
                    data: {
                        type: 'assign-reviews',
                        attributes: {
                            assigned_reviews_id: idsReviews,
                            validate_assigned: validation,
                        },
                    },
                },
            }),
            invalidatesTags: (_result, _error, arg) => [
                { type: 'Reviews', id: _.isNil(arg?.tag) ? ResponseType.LIST : arg.tag },
                { type: 'InitialCount', id: _.isNil(arg?.tag) ? ResponseType.LIST : arg.tag },
            ],
        }),

        autoAssignUser: builder.mutation({
            query: () => ({
                url: `users/auto-assign/reviews`,
                method: `PATCH`,
                body: {
                    data: {
                        type: 'auto-assign-reviews',
                        attributes: {},
                    },
                },
            }),
            invalidatesTags: (_result, error) => [
                { type: 'Reviews', id: error ? ResponseType.ERROR : ResponseType.LIST },
                { type: 'InitialCount', id: error ? ResponseType.ERROR : ResponseType.SUCCESS },
            ],
        }),

        getS3Access: builder.query<{ data: GetS3AccessResponse }, GetS3AccessQueryArg>({
            query: ({ bulk_import_id }) => ({
                url: `reviews/bulk/imports/${bulk_import_id}/link`,
                method: `GET`,
            }),
        }),

        reopenReview: builder.mutation<any, ReopenReviewQueryArg>({
            query: (req) => ({
                url: `reviews/${req.survey_response_id}/reopen`,
                method: 'PATCH',
                body: {
                    data: {
                        type: 'review-detail',
                        attributes: {
                            time: req.time,
                        },
                    },
                },
            }),
            invalidatesTags: (_result, error) => [{ type: 'ReviewsDetails', id: error ? ResponseType.ERROR : ResponseType.DETAILS }],
        }),

        addFollowUp: builder.mutation<any, AddFollowUpQueryArg>({
            query: (data) => ({
                url: `reviews/ticket/${data.id}/followup`,
                method: 'PATCH',
                body: {
                    data: {
                        type: 'add-follow-up',
                        attributes: {
                            note: data.note,
                        },
                    },
                },
            }),
            invalidatesTags: (_result, _error, arg) => [
                { type: 'ReviewsDetails', id: arg.tag },
                { type: 'MaintenanceTicketDetail', id: arg.tag },
                { type: 'Reviews', id: arg.tag },
            ],
        }),

        getTeamDetailCount: builder.query<GetTeamDetail, EmptyQueryArg>({
            query: () => {
                return {
                    url: 'reviews/team-detail',
                    method: 'GET',
                };
            },
            providesTags: ['TeamDetailCount'],
        }),
    }),
});

export const {
    useGetReviewsQuery,
    useGetReviewDetailsQuery,
    useGetAllTicketReviewsQuery,
    useLazyGetInitialCountQuery,
    useLazyGet90DayAvgQuery,
    useGetTicketDetailQuery,
    useGetBulkImportsQuery,
    useAddHistoryNoteMutation,
    useProcessReviewMutation,
    useUpdateSurveyResponseAnswerMutation,
    useCreateReviewMaintenanceTicketMutation,
    useUploadBulkReviewMutation,
    useAssignUserMutation,
    useAutoAssignUserMutation,
    useLazyGetS3AccessQuery,
    useReopenReviewMutation,
    useAddFollowUpMutation,
    useLazyGetTeamDetailCountQuery,
    useRefreshMaintenanceTicketMutation,
} = reviewsApi;
