import React from 'react';
import './SummaryContainerStyle.scss';
import * as _ from 'lodash';

interface SummaryCardProps {
    summaryTitle: string;
    summaryDetail: string;
}

export const SummaryCard: React.FC<SummaryCardProps> = (props) => {
    const { summaryTitle, summaryDetail } = props;

    const isEmptySummary = _.isEmpty(summaryDetail);

    return (
        <div className={'summary-card'}>
            <span className={'summary-title body-bold'}>{summaryTitle}</span>
            {isEmptySummary ? (
                <div className={'summary-detail body-regular'}>
                    <span>{`N/A`}</span>
                </div>
            ) : (
                <div className={'summary-detail body-regular'}>{summaryDetail}</div>
            )}
        </div>
    );
};
