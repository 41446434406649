import React from 'react';
import { Alert } from '@mui/material';
import { CSVLink } from 'react-csv';

export enum BulkMessageType {
    EXCEED_LIMIT = 'EXCEED_LIMIT',
    TO_MUCH_ERRORS = 'TO_MUCH_ERRORS',
    INCORRECT_FORMAT = 'INCORRECT_FORMAT',
    HTTP_ERROR = 'HTTP_ERROR',
    VALID_SUCCESS = 'VALID_SUCCESS',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    EMPTY_FILE = 'EMPTY_FILE',
}

export interface BulkReviewsMessageProps {
    messageType: BulkMessageType;
    data?: Array<any>;
    headers?: Array<any>;
    count?: number;
}

export const BulkReviewsMessage: React.FC<BulkReviewsMessageProps> = (props) => {
    const { messageType, data, headers, count } = props;
    switch (messageType) {
        case BulkMessageType.EMPTY_FILE:
            return <Alert severity="warning">The uploaded file is empty</Alert>;
        case BulkMessageType.EXCEED_LIMIT:
            return <Alert severity="warning">Uploaded file exceeds the limit of 1000 reviews by bulk</Alert>;
        case BulkMessageType.TO_MUCH_ERRORS:
            return (
                <Alert severity="warning">
                    <span>File with {count} data type errors, for more details. </span>
                    <CSVLink
                        filename={'reviews_bulk_validator_errors.csv'}
                        data={data!}
                        headers={headers}
                        target="_blank"
                        enclosingCharacter={`"`}
                    >
                        here
                    </CSVLink>
                </Alert>
            );
        case BulkMessageType.INCORRECT_FORMAT:
            return <Alert severity="warning">File upload has an incorrect format</Alert>;
        case BulkMessageType.HTTP_ERROR:
            return <Alert severity="error">There was an error saving your file, please try again.</Alert>;
        case BulkMessageType.UPLOAD_SUCCESS:
            return <Alert severity="success">The process is successful, your bulk has been saved and waiting to be processed.</Alert>;
        default:
            return <></>;
    }
};
