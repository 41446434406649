import * as _ from 'lodash';

const mapKeyPIIToString: { [key: string]: string } = {
    streetAddresses: 'address',
    moreTwoNumber: 'more than two numbers',
};

export const createIssuesIdentifiedText = (
    summaryIssues: string,
    piiDetail: { pii_type: string; identified_words: string[] }[]
): string => {
    let baseText = !_.isEmpty(summaryIssues) ? summaryIssues + '\n\n' : '';

    if (!_.isEmpty(piiDetail)) {
        baseText += 'Sensitive information:\n';
        piiDetail.forEach((pii_data) => {
            if (!_.isEmpty(pii_data.pii_type) && !_.isEmpty(pii_data.identified_words)) {
                const piiTypeText = formatPiiType(pii_data.pii_type);
                baseText += ` • ${piiTypeText}: ${pii_data.identified_words.join(', ')}\n`;
            }
        });
    }

    return baseText;
};

const formatPiiType = (piiType: string) => {
    if (piiType in mapKeyPIIToString) {
        const newLocalPiiType = mapKeyPIIToString[piiType];
        return newLocalPiiType ? newLocalPiiType : piiType;
    }

    const newPiiString = piiType.replace(/_/g, ' ').toLowerCase();
    return newPiiString;
};
