import React, { useState } from 'react';
import { Button } from '@vacasa/react-components-lib';
import './TextBox.scss';
import _ from 'lodash';

interface TextEditorProps {
    text: string | undefined;
    onSave: (newText: string, oldText?: string) => void;
    onCancel: () => void;
    labelSuccessBtn?: string;
    labelCancelBtn?: string;
    placeHolder?: string;
    max?: number;
    min?: number;
}

export const TextEditor: React.FC<TextEditorProps> = (props: TextEditorProps) => {
    const { text, onSave, onCancel, placeHolder, max, min, labelCancelBtn, labelSuccessBtn } = props;
    const [value, setValue] = useState<string>(text || '');

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setValue(value);
    };

    const isEmpty = _.isEmpty(value) || value === text || value.trim().length === 0;

    return (
        <div className="text-editor">
            <textarea
                maxLength={max ?? 255}
                minLength={min ?? 1}
                value={value}
                onChange={handleTextChange}
                placeholder={placeHolder ?? text}
            />
            {max && (
                <div className={'align-items-start text-left count-ch'}>
                    <span>{`${value.length}/${max}`}</span>
                </div>
            )}
            <div className="text-editor-actions">
                <Button variant="info" onClick={() => onCancel()}>
                    {labelCancelBtn ?? 'Cancel'}
                </Button>
                <Button disabled={isEmpty} variant={'secondary'} onClick={() => onSave(value, text)}>
                    {labelSuccessBtn ?? 'Save'}
                </Button>
            </div>
        </div>
    );
};
