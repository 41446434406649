import { UserResponse } from '@reviews/interfaces';

export const mapToUserSelectList = (userList: UserResponse[]): { value: string; display: string }[] => {
    let userSelectList: { value: string; display: string }[] = [];

    for (const userEl of userList) {
        userSelectList.push({
            value: `${userEl.attributes.user_id}`,
            display: userEl.attributes.name,
        });
    }

    return userSelectList;
};
