export interface ChannelReview {
    unit_id?: number;
    eventType?: string;
    confirmation_code: string;
    channel_name: string;
    channel_review_id: string;
    channel_reservation_id: string;
    channel_review: object;
}

export interface AirbnbReview {
    id: string;
    hidden: boolean;
    submitted: boolean;
    expires_at: string;
    listing_id: string;
    reviewee_id: string;
    reviewer_id: string;
    submitted_at?: string;
    public_review: string;
    reviewee_role: string;
    reviewer_role: string;
    overall_rating: number;
    category_ratings: AirbnbCategoryRating[];
    private_feedback: string;
    first_completed_at: string;
    reviewee_response?: string;
    responded_at?: string;
    reservation_confirmation_code: string;
    is_reviewee_recommended: boolean;
}

export interface AirbnbCategoryRating {
    rating: number;
    category: string;
    comment: string;
}

export enum ChannelAlias {
    Airbnb = 'airbnb',
    Booking = 'booking',
    Google = 'google',
    HomeAway = 'homeaway',
    HomeToGo = 'hometogo',
    TripAdvisor = 'tripadvisor',
    Expedia = 'expedia',
    MyBookingPal = 'mybookingpal',
    Vacasa = 'vacasa',
}

export const mapChannelAliasToSourceId: { [key: string]: number[] } = {
    [ChannelAlias.Airbnb]: [9, 8, 78, 82, 92, 417, 418, 443, 445],
    // [ChannelAlias.Booking]: 13,
};

// BOOKING REVIEWS

export interface BookingReview {
    url: string;
    reply: Reply;
    content: Content;
    scoring: Scoring;
    reviewer: Reviewer;
    review_id: string;
    reservation_id: number;
    created_timestamp: string;
    last_change_timestamp: string;
}

export interface Reply {
    text: string;
    last_change_timestamp: string;
}

export interface Content {
    headline: string;
    negative: string;
    positive: string;
    language_code: string;
}

export interface Scoring {
    clean: number;
    staff: number;
    value: number;
    comfort: number;
    location: number;
    facilities: number;
    review_score: number;
}

export interface Reviewer {
    name: string;
    is_genius: boolean;
    country_code: string;
}
