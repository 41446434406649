import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { parse, ParseResult } from 'papaparse';
import { headersDefault, headersErrors, headersResult, validateBulkData } from './BulkReviews.utils';
import './BulkReviews.scss';
import { Button } from '../Button/Button';
import { ApiRequest, useGetBulkImportsQuery, useLazyGetS3AccessQuery, useUploadBulkReviewMutation } from '../../services';
import { Icons } from '../../assests/icons';
import { getUserLoggedContext } from '../../contexts/userContext';
import { CSVLink } from 'react-csv';
import { gzipSync, strToU8 } from 'fflate';
import { encode } from 'base64-arraybuffer';
import { DataBulkTable, GetS3AccessResponse, HeaderBulkTypes } from '@reviews/interfaces';
import { ReviewsTable } from '../ReviewsTable/ReviewsTable';
import { BulkMessageType, BulkReviewsMessage } from './BulkReviewsMessage';
import { Configuration } from '../../Configuration';
import { Icon, Modal } from '@vacasa/react-components-lib';
import bulkTemplateImg from '../../assests/images/bulk_template_dummy.png';
import { QueueTypes } from '../ReviewsTable/WorkQueue.utils';
import { setEmptySearchParams } from '../../store/flow';
import { useDispatch } from 'react-redux';

export interface BulkReviewsProps {}

export const BulkReviews: React.FC<BulkReviewsProps> = (_props) => {
    const [file, setFile] = React.useState<any>(null);
    const [cloudFile, setCloudFile] = React.useState<string>('');
    const [array, setArray] = React.useState<DataBulkTable[]>([]);
    const [enableBottom, setEnableBottom] = React.useState<boolean>(false);
    const [isOpenPopup, setIsOpenPopup] = React.useState<boolean>(false);

    const [message, setMessage] = React.useState<{ type?: BulkMessageType; data?: Array<any>; headers?: Array<any>; count?: number }>({});

    const [limitRows, setLimitRows] = useState<number>(10);
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [request, setRequest] = useState<ApiRequest>({ include: 'created_by', sort: '-created_at' });
    const [page, setPage] = useState(0);
    const [note, setNote] = useState<string>('');

    const user: { email: string } = getUserLoggedContext();

    const fileReader = new FileReader();

    const { data, error, isLoading, refetch } = useGetBulkImportsQuery(request, {
        pollingInterval: Configuration.getPollingBulkMilliseconds(),
    });
    const [trigger] = useLazyGetS3AccessQuery();
    const [uploadBulkReview, { isLoading: isLoadingUpdate }] = useUploadBulkReviewMutation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setEmptySearchParams({ queueType: QueueTypes.BULK_IMPORT }));
    }, []);

    useEffect(() => {
        if (_.isNil(file)) {
            return;
        }

        const headers: Array<{ label: string; key: string }> = [];
        const arr: Array<{ [key: string]: any }> = [];

        let errorCountFile: number = 0;

        for (const row of array) {
            const errors: Array<string> = [];
            const object: { [key: string]: any } = {};

            _.forOwn(row, (value, key) => {
                validateBulkData(key as HeaderBulkTypes, _.toString(value), errors);
                object[key] = value;
            });

            if (!_.isEmpty(errors)) {
                errorCountFile++;
                object['Detail'] = errors.join(', ');
            } else {
                object['Detail'] = 'Ok';
            }

            arr.push(object);
        }

        _.entries(arr[0]).map(([key, _]) => headers.push({ label: key, key }));

        if (arr.length === 0) {
            setMessage({ type: BulkMessageType.EMPTY_FILE });
            return;
        }

        if (arr.length > 1000) {
            setMessage({ type: BulkMessageType.EXCEED_LIMIT });
            return;
        }

        if (errorCountFile > 0) {
            setMessage({ type: BulkMessageType.TO_MUCH_ERRORS, count: errorCountFile, data: arr, headers });
            return;
        }

        if (!_.isEqual(headers, headersErrors)) {
            setMessage({ type: BulkMessageType.INCORRECT_FORMAT });
            return;
        }

        setMessage({ type: BulkMessageType.VALID_SUCCESS });
    }, [array, file]);

    useEffect(() => {
        if (message.type === BulkMessageType.VALID_SUCCESS && !_.isEmpty(array)) {
            setEnableBottom(true);
        } else {
            setEnableBottom(false);
        }
    }, [array, message]);

    useEffect(() => {
        if (!_.isEmpty(cloudFile)) {
            const csvLink = document.getElementById('link-cloud');
            csvLink?.click();
            setCloudFile('');
        }
    }, [cloudFile]);

    if (isLoading) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <h1>An error occurred:</h1>
                <div>{JSON.stringify(error)}</div>
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const handleAccess = async (bulk_import_id: number) => {
        const result = await trigger({ bulk_import_id });
        const data = (result.data?.data as GetS3AccessResponse)?.attributes?.link;
        if (data) {
            const arr = data.split('\r\n');
            arr.shift();
            setCloudFile(arr.join('\r\n'));
        }
    };

    const handleOnChange = async (e: any) => {
        setMessage({});
        await parse(e.target.files[0], {
            header: true,
            complete: (results: ParseResult<Record<string, unknown>>) => {
                setArray(results.data as DataBulkTable[]);
            },
        });

        fileReader.onload = (event) => {
            setFile(event.target!.result);
        };
        fileReader.readAsText(e.target.files[0]);
    };

    const handleOnSubmit = async () => {
        const massiveFile = new Uint8Array(strToU8(file));
        const gzipped = gzipSync(massiveFile);
        const str64 = encode(gzipped);
        const validNote = _.isEmpty(note.trim()) ? '' : note;
        try {
            await uploadBulkReview({ email: user.email, file: str64, note: validNote }).unwrap();
        } catch (_e) {
            setMessage({ type: BulkMessageType.HTTP_ERROR });
            return;
        }
        refetch();
        setEnableBottom(false);
        setMessage({ type: BulkMessageType.UPLOAD_SUCCESS });
    };

    const handlePageChange = (page: number, limit: number) => {
        setSelected([]);
        setLimitRows(limit);
        setPage(page);
        setRequest((r) => ({ ...r, pageNumber: page, pageSize: limit }));
    };

    const handleSortFilter = (sortFilter: string) => {
        setSelected([]);
        setRequest((r) => ({ ...r, sort: sortFilter }));
    };

    const handleInput = () => {
        const input = document.getElementById('csvFileInput');
        input?.click();
    };

    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    return (
        <div>
            <CSVLink
                id={'link-cloud'}
                data={cloudFile}
                filename={'bulk_import_result.csv'}
                target={'_blank'}
                headers={headersResult}
                enclosingCharacter={`"`}
            />
            <div className={'bulk-main'}>
                <div className={'bulk-template'}>
                    <div className={'bulk-box'}>
                        <button className={'bulk-button'}>
                            <Icon.Download />
                            <CSVLink
                                className={'bulk-text'}
                                filename={'reviews_bulk_upload_template.csv'}
                                data={[]}
                                headers={headersDefault}
                                target="_blank"
                            >
                                {'Download Excel Template'}
                            </CSVLink>
                        </button>
                    </div>
                    <span>
                        The file format <b>must</b> be CSV. Please use the template file to avoid column name errors. If you are using
                        Excel-like software to edit the file, remember to save, export, or download it in CSV format.
                        <Icon.ZoomIn onClick={togglePopup} />
                    </span>
                </div>
                <hr />
                <div className={'bulk-body'}>
                    <div className={'bulk-input'}>
                        <button className={'bulk-button'} onClick={handleInput}>
                            <Icon.Monitor />
                            <label className={'bulk-text'}>{'Browse CSV file'}</label>
                        </button>
                        <input
                            className={'custom-file-input'}
                            type={'file'}
                            id={'csvFileInput'}
                            accept={'.csv'}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className={'bulk-note'}>
                        <label>Description:</label>
                        <input type="text" value={note} placeholder={'Add Description'} onChange={(event) => setNote(event.target.value)} />
                    </div>
                    <div className={'bulk-submit'}>
                        <Button
                            id={'assign-btn'}
                            onClick={() => handleOnSubmit()}
                            isLoading={isLoadingUpdate}
                            disabled={!enableBottom}
                            variant={'secondary'}
                            label={'Upload'}
                        />
                    </div>
                </div>
                <div className={'bulk-message'}>
                    <BulkReviewsMessage messageType={message.type!} headers={message.headers} data={message.data} count={message.count} />
                </div>
            </div>
            <hr />
            <ReviewsTable
                data={data!}
                onPageSizeChange={handlePageChange}
                onPageLimitChange={handlePageChange}
                onChangeSortFilter={(filter) => handleSortFilter(filter)}
                onChangeSelectedReviews={(val) => setSelected(val)}
                selectedReviews={selected}
                limitRows={limitRows}
                page={page}
                typeQueue={QueueTypes.BULK_IMPORT}
                totalCount={data?.meta?.pagination.count ?? 10}
                handleAccess={(id: number) => handleAccess(id)}
                skipEmptyMessage={false}
            />
            {isOpenPopup && (
                <Modal showModal={isOpenPopup} setShowModal={setIsOpenPopup} size={'large'}>
                    <div className={'bulk-rules'}>
                        <h1 className={'title'}>Excel Example</h1>
                        <p className={'description'}>
                            {
                                'Here is an example of how the file should look like with data, before saving, exporting, or downloading it in CSV format.'
                            }
                        </p>
                        <p className={'description'}>{'Please be aware of the required fields and the different column formats.'}</p>
                        <img src={bulkTemplateImg} alt={'bulk_template_dummy'} />
                    </div>
                </Modal>
            )}
        </div>
    );
};
