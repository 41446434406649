import { AutoCompleteSelect, Button, Select } from '@vacasa/react-components-lib';
import React, { ChangeEvent, useEffect } from 'react';
import * as _ from 'lodash';
import { UserResponse } from '@reviews/interfaces';
import { mapToUserSelectList } from '../AutoCompleteUsers/AutCompleteUsers.utils';
import { getCriteriaParamsByCategory, processInputText, SearchList, SearchTypes } from './SearchReview.utils';
import './SearchReview.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import { setSearchDone } from '../../store/flow';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Router';

export interface SearchReviewProps {
    userData: Array<UserResponse>;
    handleSearchFilter: (filter: { filters: { [key: string]: string } }) => void;
}

export const SearchReview: React.FC<SearchReviewProps> = (props: SearchReviewProps) => {
    const { handleSearchFilter, userData } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const flow = useSelector((state: Store) => state.flow);
    const KEY_ENTER = 'Enter';

    const defaultCategory = flow.searchParams.criteria.value || SearchTypes.ADMIN_UNIT_ID;
    const defaultValue = !_.isObject(flow.searchParams.searchValue.value) ? flow.searchParams.searchValue.value : '';
    const defaultUser = _.isObject(flow.searchParams.searchValue.value)
        ? { ...flow.searchParams.searchValue.value }
        : { value: '', display: '' };
    const [selectedCategory, setSelectedCategory] = React.useState<{ value: SearchTypes; display: string }>({
        value: defaultCategory,
        display: defaultCategory,
    });
    const [selectedUserFilter, setSelectedUserFilter] = React.useState<{ value: string; display: string }>({
        value: defaultUser.value,
        display: defaultUser.display,
    });
    const [isInputValid, setInputValid] = React.useState<boolean>(false);
    const [isInputEnabled, setInputEnable] = React.useState<boolean>(true);
    const [inputValue, setInputValue] = React.useState<string | number>(defaultValue);
    const userList: { value: string; display: string }[] = mapToUserSelectList(userData);

    useEffect(() => {
        if (!flow.searchParams.haveToLookFor && (!flow.searchParams.criteria.isValid || !flow.searchParams.searchValue.isValid)) {
            return;
        }
        const categoryFilter = { value: flow.searchParams.criteria.value, display: flow.searchParams.criteria.value };
        setSelectedCategory(categoryFilter);
        if (_.isObject(flow.searchParams.searchValue.value)) {
            setSelectedUserFilter(flow.searchParams.searchValue.value);
        } else {
            setInputValue(flow.searchParams.searchValue.value);
        }
        setInputValid(true);
        onSearchClick(true);
    }, []);

    const handleSelectUserFilter = (selectedFilter: { value: string; display: string } | null) => {
        if (!_.isNil(selectedFilter)) {
            setSelectedUserFilter(selectedFilter);
        }
    };

    const handleSelectCategory = async (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const target = event.target;
        const selectedValue = { value: target.value as SearchTypes, display: target.value };
        setInputValue('');
        setSelectedUserFilter({ value: '', display: '' });
        setSelectedCategory(selectedValue);
        setInputValid(false);
        setInputEnable(true);
    };

    const onSearchClick = (searchByLink?: boolean) => {
        let filters: { filters: { [key: string]: string } } = { filters: {} };
        if (isUserValid) {
            filters.filters[`${selectedCategory.value}`] = `${selectedUserFilter.value}`;
            setInputValue('');
            dispatch(setSearchDone({ criteria: selectedCategory.value, value: selectedUserFilter }));
        } else {
            filters.filters[`${selectedCategory.value}`] = `${inputValue}`;
            setSelectedUserFilter({ value: '', display: '' });
            dispatch(setSearchDone({ criteria: selectedCategory.value, value: inputValue }));
        }
        handleSearchFilter(filters);
        _.isNil(searchByLink) && changePath();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        const textProcess = processInputText(selectedCategory.value, text);
        if (!_.isEmpty(text) && textProcess.isValid) {
            setInputValue(textProcess.text);
            setInputValid(true);
        } else {
            setInputValue(text);
            setInputValid(false);
        }
    };

    const changePath = () => {
        const criteria = getCriteriaParamsByCategory(selectedCategory.value);
        const pathWithCriteria = AppRoutes.SEARCH_WITH_PARAMS.replace(':criteria', criteria);
        const pathWithValue = pathWithCriteria.replace(':value', _.toString(inputValue));
        navigate(pathWithValue, { replace: true });
    };

    const isUserValid = !_.isNil(selectedUserFilter) && !_.isEqual(selectedUserFilter, { value: '', display: '' });

    return (
        <div className={'search-review'}>
            <span className={'body-bold'}>{'Search by '}</span>
            <Select
                customClass={'auto-complete search-category'}
                value={selectedCategory.display}
                options={SearchList}
                onChange={(event) => handleSelectCategory(event)}
            />
            {selectedCategory.value === SearchTypes.ASSIGNED_TO ? (
                <AutoCompleteSelect
                    customClass={'auto-complete'}
                    options={userList}
                    value={selectedUserFilter}
                    onChange={(value: { value: string; display: string } | null) => handleSelectUserFilter(value)}
                    getOptionLabel={(options: { value: string; display: string }) => options.display}
                    disabled={false}
                />
            ) : (
                <input
                    id="title"
                    className={'search-input'}
                    type="text"
                    name="search-input"
                    onChange={(event) => handleInputChange(event)}
                    onKeyDown={(event) => event.key === KEY_ENTER && onSearchClick()}
                    value={inputValue}
                    disabled={!isInputEnabled}
                />
            )}
            <Button
                customClass={'search-btn'}
                onClick={() => onSearchClick()}
                disabled={!(isUserValid || isInputValid)}
                variant="secondary"
            >
                Search
            </Button>
        </div>
    );
};
