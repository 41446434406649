import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { Order } from '../../utils/shared.utils';
import { HeadCell } from './WorkQueue.utils';
import { Checkbox } from '@vacasa/react-components-lib';
interface EnhancedTableProps {
    headers: HeadCell[];
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    checkboxEnable: boolean;
}

export const TableHeader: React.FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
    const { checkboxEnable, headers, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {checkboxEnable && (
                    <TableCell className={'body-small-bold'} padding="checkbox">
                        <Checkbox
                            checked={rowCount > 0 && numSelected === rowCount}
                            key={'select all reviews'}
                            onChange={onSelectAllClick}
                            type={'info'}
                            label={''}
                        />
                    </TableCell>
                )}
                {headers.map((header) => (
                    <TableCell
                        className={'body-small-bold'}
                        key={String(header.id)}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === header.id ? order : false}
                    >
                        {header.sortable ? (
                            <TableSortLabel
                                active={orderBy === header.id}
                                direction={orderBy === header.id ? order : 'asc'}
                                onClick={createSortHandler(header.id)}
                            >
                                <span className={'body-small-bold'}>{header.label}</span>
                                {orderBy === header.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>
                                <span className={'body-small-bold'}>{header.label}</span>
                            </div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
