import React, { useState } from 'react';
import { Icon, withTooltip } from '@vacasa/react-components-lib';
import './TextBox.scss';

interface EditorModeProps {
    onEdit?: (newText: string, oldText: string, attribute: string) => void;
    value: string;
    setValueEdit: (e: boolean) => void;
    isTitle: boolean;
}

export const EditorMode: React.FC<EditorModeProps> = (props: EditorModeProps) => {
    const { onEdit, value, setValueEdit, isTitle } = props;
    const [editValue, setEditValue] = useState(value);
    const style: string = isTitle ? 'title-editor-actions' : 'text-editor-actions';

    if (!onEdit) {
        return null;
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEditValue(value);
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setEditValue(value);
    };

    return (
        <div className={style}>
            {isTitle ? (
                <input type="text" maxLength={128} value={editValue} onChange={(e) => handleTitleChange(e)} />
            ) : (
                <textarea maxLength={3000} value={editValue} onChange={(e) => handleTextChange(e)} />
            )}
            <div className="editor-button">
                {withTooltip(
                    'Cancel',
                    <span className="button">
                        <Icon.X onClick={() => setValueEdit(false)} />
                    </span>
                )}
                {withTooltip(
                    'Save',
                    <span className="button">
                        <Icon.Check
                            onClick={() => {
                                onEdit(editValue, value, isTitle ? 'title' : 'answer');
                                setValueEdit(false);
                            }}
                        />
                    </span>
                )}
            </div>
        </div>
    );
};
