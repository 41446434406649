import { QueueTypes } from '../../components/ReviewsTable/WorkQueue.utils';
import { Roles } from '@reviews/interfaces';

export const getPermission = (queueType: QueueTypes, role: Roles): boolean => {
    switch (queueType) {
        case QueueTypes.NEW:
        case QueueTypes.MINE:
        case QueueTypes.RESPONSE_REQUIRED:
            return [Roles.ADMIN, Roles.TEAM_LEAD, Roles.AGENT].includes(role);
        case QueueTypes.SEARCH:
            return [Roles.ADMIN, Roles.TEAM_LEAD, Roles.AGENT, Roles.EMPLOYEE].includes(role);
        case QueueTypes.BULK_IMPORT:
        case QueueTypes.MY_TEAM:
            return [Roles.ADMIN, Roles.TEAM_LEAD].includes(role);
    }
};
