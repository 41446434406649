import { createInstance, ReactSDKClient, setLogger } from '@optimizely/react-sdk';
import _ from 'lodash';

class OptimizelyService {
    private optimizelyClientInstance: ReactSDKClient | null = null;

    getOptimizelyInstance(): ReactSDKClient | never {
        if (!_.isNil(this.optimizelyClientInstance)) return this.optimizelyClientInstance;

        const sdkKey = process.env['REACT_APP_OPTIMIZELY_KEY'];

        if (!sdkKey) {
            throw new Error('No SDK key provided');
        }

        this.optimizelyClientInstance = createInstance({ sdkKey });
        setLogger(null);

        return this.optimizelyClientInstance;
    }
}

export default new OptimizelyService();
