import { useLazyGetTeamDetailCountQuery } from '../../services';
import { Icons } from '../../assests/icons';
import { ReviewsTable } from '../ReviewsTable/ReviewsTable';
import React, { useEffect } from 'react';
import { QueueTypes } from '../ReviewsTable/WorkQueue.utils';
import { useDispatch } from 'react-redux';
import { setEmptySearchParams } from '../../store/flow';

export function TeamDetail() {
    const [trigger] = useLazyGetTeamDetailCountQuery({});
    const [response, setResponse] = React.useState<{ data: any; isLoading: any; isError: any; error: any }>({
        data: null,
        isLoading: true,
        isError: null,
        error: null,
    });
    const [selected, setSelected] = React.useState<readonly number[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setEmptySearchParams({ queueType: QueueTypes.MY_TEAM }));
    }, []);

    useEffect(() => {
        (async () => {
            const { data, isLoading, isError, error } = await trigger({});
            setResponse({ data, isLoading, isError, error });
        })();
    }, []);

    if (response.isLoading) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    if (response.error) {
        return (
            <div>
                <h1>An error occurred:</h1>
                <div>{JSON.stringify(response.error)}</div>
            </div>
        );
    }

    if (!response.data) {
        return null;
    }

    return (
        <div>
            <ReviewsTable
                data={response.data!}
                onPageSizeChange={() => {}}
                onPageLimitChange={() => {}}
                onChangeSortFilter={() => {}}
                onChangeSelectedReviews={(val) => setSelected(val)}
                selectedReviews={selected}
                limitRows={500}
                page={0}
                typeQueue={QueueTypes.MY_TEAM}
                totalCount={response.data?.data?.length ?? 10}
                skipEmptyMessage={false}
            />
        </div>
    );
}
