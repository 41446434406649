import React, { useCallback, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper } from '@mui/material';

import { EnhancedTableToolbar } from '..';
import { getComparator, isProcessedReview, Order, stableSort } from '../../utils/shared.utils';
import { TableHeader } from './TableHeader';
import { Checkbox } from '@vacasa/react-components-lib';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import './TableStyles.scss';
import * as _ from 'lodash';
import { mapRowByType, mapTypeToTableHeader, QueueTypes, renderEmptyModal, ReviewTableData } from './WorkQueue.utils';
import { useDispatch } from 'react-redux';
import { setCurrent } from '../../store/flow';
import { BulkImportStatus, ReviewStatus } from '@reviews/interfaces';
import { debounce } from 'lodash';

export type PaginationRequest = (page: number, limit: number, sortCriteria: string, sortDirection: string) => void;

export const dicSortFilters: { [key: string]: string } = {
    review_id: 'review.review_id',
    review_date: 'review.review_date,-review.review_id',
    unit_id: 'unit.unit_id,review.review_id',
    admin_unit_id: 'unit.admin_unit_id,review.review_id',
    unit_code: 'unit.unit_code,review.review_id',
    rating: 'review.rating,review.review_id',
    reservation_id: 'admin_reservation_id',
    assigned_to: 'assigned_to.name,review.review_id',
    assigned_on: 'assigned_at,review.review_id',
    updated_at: 'updated_at,review.review_id',
    ticket_created_at: 'maintenance_ticket.created_at',
    ticket: 'maintenance_ticket.admin_ticket_id',
    follow_up_date: 'maintenance_ticket.follow_up_date',
    update: 'maintenance_ticket.updated_at',
    bulk_import_id: 'bulk_import_id',
    row_count: 'row_count',
    status: 'review.status,review.review_id',
    name: 'created_by.name',
    bulk_import_created_at: 'created_at',
    bulk_import_updated_at: 'updated_at',
    agent_name: 'agent_name',
    pending_reviews_count: 'pending_reviews_count',
    response_required_count: 'response_required_count',
    total_count: 'total_count',
    oldest_assigned: 'oldest_assigned',
};

interface ReviewsTableProps {
    data: ReviewTableData;
    onPageSizeChange: PaginationRequest;
    onPageLimitChange: PaginationRequest;
    selectedReviews: readonly number[];
    onChangeSelectedReviews: (id: readonly number[]) => void;
    onChangeSortFilter: (filter: string) => void;
    limitRows: number;
    page: number;
    typeQueue: QueueTypes;
    totalCount: number;
    skipEmptyMessage: boolean;
    handleAccess?: (id: number) => void;
    onOpenFollowUpModal?: (id?: number, followUpStatus?: string, adminTicketId?: number) => void;
    emptyBtnHandler?: (() => void) | undefined;
    loadingData?: boolean;
    autoAssignBtn?: boolean;
}

export const ReviewsTable: React.FC<ReviewsTableProps> = (props) => {
    const dispatch = useDispatch();
    const {
        data,
        onPageSizeChange,
        onPageLimitChange,
        selectedReviews,
        onChangeSelectedReviews,
        onChangeSortFilter,
        limitRows,
        page,
        typeQueue,
        totalCount,
        handleAccess,
        skipEmptyMessage,
        onOpenFollowUpModal,
        emptyBtnHandler,
        autoAssignBtn,
    } = props;
    const [rows, setRows] = useState<any[]>([]);
    const [orderDirection, setOrderDirection] = React.useState<Order>('desc');
    const [orderCriteria, setOrderCriteria] = React.useState<string>('');

    const [loadingRows, setLoadingRows] = React.useState(true);

    const navigate = useNavigate();

    const headers = mapTypeToTableHeader[typeQueue];

    useEffect(() => {
        setLoadingRows(true);
        const r = mapRowByType(typeQueue, data);
        setRows(r!);
        setLoadingRows(false);
    }, [data]);

    useEffect(() => {
        setLoadingRows(true);
        const r = mapRowByType(typeQueue, data);
        const rowSorted = typeQueue === QueueTypes.MY_TEAM ? stableSort(r as any, getComparator(orderDirection, orderCriteria)) : r;
        setRows(rowSorted!);
        setLoadingRows(false);
    }, [orderDirection, orderCriteria]);

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderCriteria === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderCriteria(property);
        const sort = isAsc ? '-' : '';
        onChangeSortFilter(`${sort}${dicSortFilters[property]}`);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedIds = rows.filter((n) => !isProcessedReview(n.status)).map((n) => +n.review_id);
            onChangeSelectedReviews(newSelectedIds);

            return;
        }
        onChangeSelectedReviews([]);
    };

    const handleSelection = (_event: React.ChangeEvent<unknown>, id: number) => {
        const selectedIndex = selectedReviews.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedReviews, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedReviews.slice(1));
        } else if (selectedIndex === selectedReviews.length - 1) {
            newSelected = newSelected.concat(selectedReviews.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedReviews.slice(0, selectedIndex), selectedReviews.slice(selectedIndex + 1));
        }

        onChangeSelectedReviews(newSelected);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangePage = useCallback(
        debounce((_event: unknown, newPage: number) => {
            onPageSizeChange(newPage, limitRows, orderCriteria, orderDirection);
        }, 300),
        [limitRows, onPageSizeChange, orderCriteria, orderDirection]
    );

    const handleReviewDetail = (_event: React.MouseEvent<unknown>, id: string) => {
        dispatch(setCurrent({ review_id: id }));
        navigate(AppRoutes.REVIEW_DETAIL.replace(':id', id));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const limit = parseInt(event.target.value, 10);
        onPageLimitChange(0, limit, orderCriteria, orderDirection);
    };

    const openInNewTab = (url: string) => {
        const { REACT_APP_ADMIN_URL } = process.env;

        const openUrl = REACT_APP_ADMIN_URL + url;
        window.open(openUrl, '_blank', 'noopener,noreferrer');
    };

    const isSelected = (id: number) => selectedReviews.indexOf(id) !== -1;

    const emptyRows = rows.length !== 0 && rows.length <= 10 ? 10 - rows.length : 0;

    const checkboxEnable = typeQueue !== QueueTypes.BULK_IMPORT && typeQueue !== QueueTypes.MY_TEAM;
    const paginationEnable = typeQueue !== QueueTypes.MY_TEAM;

    const enabledCheckRows = rows.filter((r) => r.status !== ReviewStatus.SUPPRESSED && r.status !== ReviewStatus.PUBLISHED);

    return (
        <Box className={'table-list'} sx={{ width: '100%', height: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                        <TableHeader
                            headers={headers}
                            numSelected={selectedReviews.length}
                            order={orderDirection}
                            orderBy={orderCriteria}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={enabledCheckRows.length}
                            checkboxEnable={checkboxEnable}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(Number(row.review_id));
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow className={'body-small-regular'} hover tabIndex={-1} key={row.review_id}>
                                        {checkboxEnable && (
                                            <TableCell className={'m-5 body-small-regular'} padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    key={labelId}
                                                    onChange={(event) => handleSelection(event, Number(row.review_id))}
                                                    type={'default'}
                                                    label={''}
                                                    disabled={isProcessedReview(row.status)}
                                                />
                                            </TableCell>
                                        )}

                                        {headers.map((h) =>
                                            h.id === '' ? (
                                                <TableCell className={'body-small-regular'} align="left">
                                                    {''}
                                                </TableCell>
                                            ) : h.action ? (
                                                <TableCell
                                                    className={'body-small-regular right-btn'}
                                                    onClick={(event) => handleReviewDetail(event, String(row.review_id))}
                                                >
                                                    {h.iconAction}
                                                </TableCell>
                                            ) : h.actionModal ? (
                                                <TableCell
                                                    className={'body-small-regular right-btn'}
                                                    onClick={() =>
                                                        onOpenFollowUpModal &&
                                                        onOpenFollowUpModal(row.ticket_id, row.follow_up_status, row.ticket)
                                                    }
                                                >
                                                    {h.iconAction}
                                                </TableCell>
                                            ) : h.link ? (
                                                <TableCell
                                                    onClick={() => openInNewTab(`tickets/view?TicketID=${row[h.id]}&Maintenance=1`)}
                                                    align="left"
                                                    className={'body-small-regular'}
                                                >
                                                    <span className={'link-style'}>{row[h.id]}</span>
                                                </TableCell>
                                            ) : h.bulkPermission && handleAccess ? (
                                                row.status !== BulkImportStatus.BULK_IN_PROGRESS ? (
                                                    <TableCell
                                                        className={'body-small-regular right-btn'}
                                                        onClick={() => handleAccess(row.bulk_import_id)}
                                                    >
                                                        {h.iconAction}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell className={'body-small-regular'}>{h.iconNoAction}</TableCell>
                                                )
                                            ) : (
                                                <TableCell className={'body-small-regular'} align="left">
                                                    {row[h.id]}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {_.isEmpty(rows) && !loadingRows && !skipEmptyMessage && renderEmptyModal(typeQueue, emptyBtnHandler, autoAssignBtn)}
                <EnhancedTableToolbar numSelected={selectedReviews.length} />
                {paginationEnable && (
                    <>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={limitRows}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </Box>
    );
};
