import { configureStore } from '@reduxjs/toolkit';
import { reviewsApi, usersApi } from '../services';
import flowReducer, { FlowState } from './flow';
import { rtkQueryErrorFormatter } from './error.middleware';

export type Store = {
    flow: FlowState;
    reviewsApi: {
        config: any;
        mutations: any;
        provided: any;
        queries: any;
        subscriptions: any;
    };
    userApi: {
        config: any;
        mutations: any;
        provided: any;
        queries: any;
        subscriptions: any;
    };
};

export const store = configureStore({
    reducer: {
        flow: flowReducer,
        [reviewsApi.reducerPath]: reviewsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorFormatter).concat(reviewsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
