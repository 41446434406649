import jwtDecode from 'jwt-decode';
import { StorageKeys, IdentityToken } from '@reviews/frontend';

export const loadUserContext = (): void => {
    const identity: IdentityToken = jwtDecode(localStorage.getItem(StorageKeys.ID_TOKEN) as string);
    const user = {
        email: identity.email,
    };
    setLocalUserContext(user);
};

export const getLocalUserContext = () => {
    const localStorageUser = localStorage.getItem('userContext');
    return JSON.parse(localStorageUser as string);
};

const setLocalUserContext = (context: any) => {
    localStorage.setItem('userContext', JSON.stringify(context));
};

export const setUserLoggedContext = (context: any) => {
    localStorage.setItem('userLogged', JSON.stringify(context));
};

export const clearUserLoggedContext = () => {
    localStorage.removeItem('userLogged');
};

export const getUserLoggedContext = () => {
    const localStorageUserLogged = localStorage.getItem('userLogged');
    return JSON.parse(localStorageUserLogged as string);
};
