import * as _ from 'lodash';

export class Configuration {
    public static getVacasaAdminUrl(): string {
        const { REACT_APP_ADMIN_URL } = process.env;
        return REACT_APP_ADMIN_URL ?? '';
    }

    public static getReviewsManagementUrl(): string {
        const { REACT_APP_IDP_REDIRECT_URL } = process.env;
        return REACT_APP_IDP_REDIRECT_URL ?? '';
    }

    public static getReviewsManagementAPIUrl(): string {
        const { REACT_APP_REVIEWS_MANAGEMENT_API_URL } = process.env;
        return REACT_APP_REVIEWS_MANAGEMENT_API_URL ?? '';
    }

    public static getPollingBulkMilliseconds(): number {
        const { REACT_APP_POLLING_BULK_MILLISECONDS } = process.env;
        return _.toNumber(REACT_APP_POLLING_BULK_MILLISECONDS) ?? 10000;
    }

    public static isProduction(): boolean {
        const { REACT_APP_CUSTOM_NODE_ENV } = process.env;
        return REACT_APP_CUSTOM_NODE_ENV === 'production';
    }
    public static getAppVersion(): string {
        const { REACT_APP_VERSION } = process.env;
        return REACT_APP_VERSION ?? '';
    }

    public static getProductName(): string {
        const { REACT_APP_PRODUCT_NAME } = process.env;
        return REACT_APP_PRODUCT_NAME ?? '';
    }

    static getIDPConfigurations = () => {
        const { REACT_APP_IDP_CLIENT_ID, REACT_APP_IDP_REDIRECT_URL, REACT_APP_IDP_AUDIENCE } = process.env;

        return {
            clientId: REACT_APP_IDP_CLIENT_ID || '',
            scopes: 'reviews:admin reviews:read reviews:write',
            audience: REACT_APP_IDP_AUDIENCE || '',
            directoryHint: 'onelogin',
            redirectUrl: REACT_APP_IDP_REDIRECT_URL || '',
            env: (Configuration.isProduction() ? 'prod' : 'stage') as any,
        };
    };
}
