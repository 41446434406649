import React from 'react';
import { Button, Modal } from '@vacasa/react-components-lib';
import './ReOpenReviewModal.scss';
import { Spinner } from '../../assests/icons/icons';

interface ReOpenReviewModalProps {
    showModal: boolean;
    onHide: () => void;
    handleAction: (id: number) => void;
    handleOnRefresh: () => void;
    data: any;
    isLoading: boolean;
    error: any;
}

const ReOpenReviewModal: React.FC<ReOpenReviewModalProps> = (props) => {
    const { showModal, onHide, handleAction, data, isLoading, error, handleOnRefresh } = props;

    const getModalBody = (): JSX.Element => {
        return (
            <React.Fragment>
                {error ? (
                    <span className={'open-review-error'}>
                        An error occurred while reopening the review. Please refresh the page to see recent updates.
                    </span>
                ) : (
                    <div className={'open-review-msg'}>
                        <p className={'info-text'}>The review will be assigned to you with a pending status.</p>
                    </div>
                )}

                <div className="open-review-actions">
                    <Button customClass={'cancel-btn'} variant="info" onClick={onHide}>
                        Cancel
                    </Button>
                    {!error ? (
                        <Button variant="secondary" onClick={() => handleAction(data.survey_response_id)}>
                            {'Reopen Review'}
                        </Button>
                    ) : (
                        <Button variant="secondary" onClick={() => handleOnRefresh()}>
                            {'Refresh Review'}
                        </Button>
                    )}
                </div>
            </React.Fragment>
        );
    };

    return (
        <Modal showModal={showModal} setShowModal={onHide} size="medium">
            <div>
                <span className={'body-small-bold'}>{`Open Review`}</span>
            </div>
            <div>
                {isLoading ? (
                    <div className="processing-spinner">
                        <Spinner />
                    </div>
                ) : (
                    getModalBody()
                )}
            </div>
        </Modal>
    );
};

export default ReOpenReviewModal;
