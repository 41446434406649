import React from 'react';
import './SummaryContainerStyle.scss';
import { ReviewAutoProcessSummary } from '@reviews/interfaces';
import { SummaryCard } from './SummaryCard';
import * as _ from 'lodash';
import { createIssuesIdentifiedText } from './SummaryContainer.utils';

interface SummaryContainerProps {
    reviewSummaryData: ReviewAutoProcessSummary | null;
}

export const SummaryContainer: React.FC<SummaryContainerProps> = (props) => {
    const { reviewSummaryData } = props;

    const summaryIssues = reviewSummaryData?.issues_summary || '';
    const summaryGeneral = reviewSummaryData?.general_summary || '';
    const piiDetail = reviewSummaryData?.issues_pii || [];

    return (
        <div className={'summary-container'}>
            <SummaryCard summaryTitle={'General Summary'} summaryDetail={summaryGeneral} />
            <SummaryCard summaryTitle={'Issues Identified'} summaryDetail={createIssuesIdentifiedText(summaryIssues, piiDetail)} />
        </div>
    );
};
