import React, { useEffect, useState } from 'react';
import { Tabs } from '@vacasa/react-components-lib';
import '../../assests/styles/shared.scss';
import './Home.scss';
import { TeamDetail, WorkQueue, BulkReviews } from '../../components';
import { useLazyGetInitialCountQuery } from '../../services';
import { Roles } from '@reviews/interfaces';
import Switch from '@material-ui/core/Switch';
import * as _ from 'lodash';
import { numberWithCommas } from '../../utils/shared.utils';
import { useDispatch, useSelector } from 'react-redux';
import { setInitFilters, setMyWorkFilter } from '../../store/flow';
import { QueueTypes } from '../../components/ReviewsTable/WorkQueue.utils';
import { Store } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes, queueTypeToAppRoute } from '../../Router';
import { Icons } from '../../assests/icons';
import { getPermission } from './Home.util';

interface HomeProps {
    queueType?: QueueTypes;
}

export const Home: React.FC<HomeProps> = (props) => {
    let { queueType } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const flow = useSelector((state: Store) => state.flow);
    const isEmployee = flow.user?.role === Roles.EMPLOYEE;
    const isMyFirstTimeAndHavePermission = !_.isNil(queueType) && getPermission(queueType, flow.user?.role!);
    const typeIndex: number = isMyFirstTimeAndHavePermission ? queueTypeToAppRoute(flow.user?.role)[queueType!].index : flow.queue.index;
    const [selectedTab, setSelectedTab] = useState<number>(typeIndex);
    const [getInitialCount, { isLoading: isLoadingInitialCount }] = useLazyGetInitialCountQuery();
    const formatQuantity = numberWithCommas(flow?.initialCountBase?.total);

    const refetch = async () => {
        if (_.isNil(flow.user)) {
            return;
        }
        await getInitialCount({ email: flow.user.email });
    };

    useEffect(() => {
        if (!_.isNil(queueType)) {
            const searchParams = new URLSearchParams(search);
            const type: QueueTypes = !_.isNil(queueType) && getPermission(queueType, flow.user?.role!) ? queueType : flow.queue.type;
            dispatch(setInitFilters({ queueType: type, searchParams }));
        }
        setSelectedTab(typeIndex);
    }, [flow.user]);

    useEffect(() => {
        (async () => {
            await refetch();
        })();
    }, [flow.user, flow.queue.type]);

    let tabs = [
        {
            id: 'new',
            label: `New (${flow.initialCountBase.new})`,
            path: AppRoutes.NEW,
            disabled: !getPermission(QueueTypes.NEW, flow.user?.role!),
            component: <WorkQueue type={QueueTypes.NEW} startCount={flow.initialCountBase.new_count_by_rating} />,
        },
        {
            id: 'my-work',
            label: `Assigned/Pending (${flow.initialCountBase.pending_assigned}/${flow.initialCountBase.pending})`,
            path: AppRoutes.PENDING,
            disabled: !getPermission(QueueTypes.MINE, flow.user?.role!),
            component: (
                <WorkQueue
                    type={QueueTypes.MINE}
                    startCount={
                        flow.myWorkFilter ? flow.initialCountBase.assigned_count_by_rating : flow.initialCountBase.pending_count_by_rating
                    }
                />
            ),
        },
        {
            id: 'response-require',
            label: `Response Required (${flow.initialCountBase.response_required_assigned}/${flow.initialCountBase.response_required})`,
            path: AppRoutes.RESPONSE_REQUIRED,
            disabled: !getPermission(QueueTypes.RESPONSE_REQUIRED, flow.user?.role!),
            component: <WorkQueue type={QueueTypes.RESPONSE_REQUIRED} />,
        },
        {
            id: 'search',
            label: 'Search',
            path: AppRoutes.SEARCH,
            disabled: !getPermission(QueueTypes.SEARCH, flow.user?.role!),
            component: <WorkQueue type={QueueTypes.SEARCH} />,
        },
        {
            id: 'bulk',
            label: 'Bulk reviews',
            path: AppRoutes.BULK_REVIEWS,
            disabled: !getPermission(QueueTypes.BULK_IMPORT, flow.user?.role!),
            component: <BulkReviews />,
        },
        {
            id: 'my-team',
            label: 'Team',
            path: AppRoutes.TEAM,
            disabled: !getPermission(QueueTypes.MY_TEAM, flow.user?.role!),
            component: <TeamDetail />,
        },
    ];

    const onChange = (index: number) => {
        navigate(tabs[index].path, { replace: true });
        setSelectedTab(index);
    };

    if (_.isNil(flow.user) || isLoadingInitialCount) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="card home">
                <div className="card-body">
                    <div className={'my-work-ctn'}>
                        {!isEmployee && (
                            <div className={'check'}>
                                <label className={'body-regular'}>My Work</label>
                                <Switch
                                    className={''}
                                    checked={flow.myWorkFilter}
                                    onChange={() => dispatch(setMyWorkFilter({ myWorkFilter: !flow.myWorkFilter }))}
                                />
                            </div>
                        )}
                        <div className={'m-0'}>
                            <p className={'m-0 body-regular'}> {`${formatQuantity} Reviews to process`}</p>
                        </div>
                    </div>
                    <Tabs
                        variant={'default'}
                        selected={selectedTab}
                        customClass="queue-tabs"
                        tabs={_.filter(tabs, ({ disabled }) => !disabled)}
                        onChange={(index) => onChange(index)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
