import React, { useEffect, useState } from 'react';
import { AlertMessage, Button } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import './ProcessReview.scss';
import { Switch } from '@mui/material';
import { Icons } from '../../assests/icons';
import { useProcessReviewMutation } from '../../services';
import { getDefaultValue } from './ProcessReview.utils';
import { DetailedError, ErrorTypes } from '@reviews/interfaces';
import { ProcessReviewQueryArg } from '../../types/review-service.type';
import { getDiffTimeInSeg } from '../../utils/shared.utils';
import { useLoading } from '../../contexts/LoadingContext';

interface ProcessReviewProps {
    reviewId: number;
    survey_response_id: number;
    unitId: number;
    isClose: boolean;
    initDisplayReview: boolean;
    initDisplayRating: boolean;
    initResponse: { response: string; display_rating_notes: string; display_review_notes: string };
    onOpenReview: () => void;
    emptyReview: boolean;
    defaultSuppress: boolean;
    isEmployee: boolean;
    validationAssign: boolean;
    openRefreshModal: () => void;
    isLoadingReview: boolean;
    startTime: number;
    setStartTime: (value: number) => void;
}

export const ProcessReview: React.FC<ProcessReviewProps> = (props: ProcessReviewProps) => {
    const {
        reviewId,
        survey_response_id,
        isClose,
        initDisplayReview,
        initDisplayRating,
        initResponse,
        onOpenReview,
        emptyReview,
        defaultSuppress,
        isEmployee,
        validationAssign,
        openRefreshModal,
        isLoadingReview,
        startTime,
        setStartTime,
    } = props;

    const [processObj, setProcessObj] = useState<{ response: string; showRating: boolean; showReview: boolean }>({
        response: '',
        showRating: true,
        showReview: true,
    });

    const [disableShowReview, setDisableShowReview] = useState<boolean>(false);
    const [processReview, { isLoading: isProcessingReview }] = useProcessReviewMutation();
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const [labelText, setLabelText] = useState('Response');

    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { setLoadingConfig } = useLoading();

    useEffect(() => {
        const { initShowReview, initShowRating, initResponseData } = getDefaultValue(
            initDisplayRating,
            initDisplayReview,
            initResponse,
            isClose,
            emptyReview,
            defaultSuppress
        );

        setProcessObj({
            response: initResponseData,
            showReview: initShowReview,
            showRating: initShowRating,
        });
        setShowErrorAlert(false);
    }, [reviewId, validationAssign]);

    useEffect(() => {
        setInitialTexts();
    }, [processObj.showReview, processObj.showRating]);
    const handleResponseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = e;
        setProcessObj((p) => ({ ...p, response: value }));
    };
    const isSuppress = !processObj.showRating && !processObj.showReview;

    const handleOnChangeShowRating = () => {
        const newValue = !processObj.showRating;

        if (!newValue) {
            setProcessObj((p) => ({ ...p, showReview: false }));
            setDisableShowReview(true);
        } else {
            setDisableShowReview(false);
        }
        setProcessObj((p) => ({ ...p, showRating: newValue }));
    };
    const setInitialTexts = () => {
        if (isSuppress) {
            setProcessObj((p) => ({ ...p, response: initResponse.display_rating_notes }));
            setLabelText('Hide Rating Reason: ');
            return;
        }
        if (processObj.showRating && !processObj.showReview) {
            setProcessObj((p) => ({ ...p, response: initResponse.display_review_notes }));
            setLabelText('Hide Comment Reason: ');
            return;
        }

        setProcessObj((p) => ({ ...p, response: initResponse.response }));
        setLabelText('Response ');
        return;
    };

    const getBtnText = () => {
        if (isClose) {
            return 'Reopen Review';
        }
        if (isSuppress) {
            return 'Suppress';
        }

        return 'Publish';
    };

    const handleOnSubmit = async () => {
        setIsSuccess(false);
        setShowErrorAlert(false);

        const request: ProcessReviewQueryArg = {
            id: survey_response_id,
            response: processObj.response,
            show_rating: processObj.showRating,
            show_review: processObj.showReview,
            validate_assigned: validationAssign,
            time: getDiffTimeInSeg(Date.now(), startTime),
        };
        setLoadingConfig(true, false);
        try {
            await processReview(request).unwrap();

            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 3000);
            setStartTime(Date.now());
        } catch (e) {
            const kindError = (e as DetailedError)?.code || ErrorTypes.UNHANDLED;
            const isAssignError = kindError === ErrorTypes.ASSIGNED;
            if (isAssignError) {
                openRefreshModal();
            }
            setShowErrorAlert(true);
        } finally {
            setLoadingConfig(false);
        }
    };

    const handlerOpenReview = () => {
        onOpenReview();
    };

    const isLoading = isProcessingReview || isLoadingReview;

    if (isLoading) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    return (
        <div className="body-small-regular process-review-sidebar">
            {isSuppress && (
                <div className={'alert-container'}>
                    <AlertMessage
                        customClass={'alert'}
                        height={'small'}
                        text={' Reviews with hidden ratings must be suppressed.'}
                        type="info"
                    />
                </div>
            )}
            {isSuccess && (
                <div className={'alert-container'}>
                    <AlertMessage customClass={'alert'} height={'small'} text={'Review updated successfully.'} type="success" />
                </div>
            )}
            {showErrorAlert && (
                <div className={'alert-container'}>
                    <AlertMessage customClass={'alert'} height={'small'} text={'Error processing the review.'} type="error" />
                </div>
            )}
            <>
                <div className={' process-review-options d-flex mb-3 justify-content-between align-items-center'}>
                    <div className={'d-flex align-items-center'}>
                        <label>Show Rating</label>
                        <Switch
                            disabled={isClose || isEmployee || defaultSuppress}
                            checked={processObj.showRating}
                            onChange={handleOnChangeShowRating}
                        />
                    </div>
                    <div className={' d-flex align-items-center'}>
                        <label>Show Public Comment</label>
                        <Switch
                            disabled={disableShowReview || isClose || emptyReview || isEmployee || defaultSuppress}
                            checked={processObj.showReview}
                            onChange={() => setProcessObj((p) => ({ ...p, showReview: !processObj.showReview }))}
                        />
                    </div>
                </div>
                <div className={'process-review-textarea'}>
                    <label className={'body-small-bold'}>{labelText}</label>
                    {!isClose && !isEmployee ? (
                        <div>
                            <div className={'d-flex justify-content-end icon'}>
                                <Icons.Note className={'icon-note'} />
                            </div>
                            <textarea className="textarea-response" value={processObj.response} onChange={handleResponseChange} />
                        </div>
                    ) : (
                        <div>
                            {!_.isEmpty(processObj.response) ? (
                                <span className={'body-small-regular'}> {processObj.response} </span>
                            ) : (
                                <span className={'body-small-regular process-review-empty'}> Empty </span>
                            )}
                        </div>
                    )}
                </div>
                <div className="process-review-btn">
                    {!isClose ? (
                        <Button
                            disabled={isEmployee}
                            customClass={isSuppress ? 'suppress-btn' : 'success-btn'}
                            onClick={handleOnSubmit}
                            variant={'secondary'}
                        >
                            {getBtnText()}
                        </Button>
                    ) : (
                        <Button disabled={isEmployee} customClass={'success-btn'} onClick={handlerOpenReview} variant={'secondary'}>
                            {getBtnText()}
                        </Button>
                    )}
                </div>
            </>
        </div>
    );
};
