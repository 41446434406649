import { Modal } from '@vacasa/react-components-lib';
import { FollowUpForm } from '../FollowUpForm/FollowUpForm';
import { FollowUpData, FollowUpStatus } from '@reviews/interfaces';
import './FollowUpModal.tsx.scss';
interface FollowUpModalForm {
    showModal: boolean;
    onHide: () => void;
    maintenanceTicketObj: { maintenanceTicketId: number; adminTicketId: number; followUpStatus: string };
}

export const FollowUpModal: React.FC<FollowUpModalForm> = (props) => {
    const { onHide, showModal, maintenanceTicketObj } = props;
    const isDisabledFollowUp = maintenanceTicketObj.followUpStatus === FollowUpStatus.Resolution;
    const currentFollowUpStatus = FollowUpData[maintenanceTicketObj.followUpStatus]?.name ?? '';
    const currentTicketId = maintenanceTicketObj.adminTicketId;

    const openInNewTab = () => {
        const { REACT_APP_ADMIN_URL } = process.env;

        const openUrl = REACT_APP_ADMIN_URL + `tickets/view?TicketID=${currentTicketId}&Maintenance=1`;
        window.open(openUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <Modal showModal={showModal} setShowModal={onHide} size="medium">
            <div>
                <h4>{'Add Follow Up'}</h4>
            </div>
            <div>
                <div className={'m-0'}>
                    <span className={'body-bold'}>Ticket: </span>
                    <span className={'body-regular link'} onClick={openInNewTab}>
                        {currentTicketId}
                    </span>
                </div>

                <div>
                    <span className={'body-bold'}>Current Follow Up Status: </span>
                    <span className={'body-regular'}>{currentFollowUpStatus}</span>
                </div>
            </div>
            <div>
                <FollowUpForm
                    onHide={onHide}
                    showForm={true}
                    maintenanceTicketId={maintenanceTicketObj.maintenanceTicketId}
                    disabled={isDisabledFollowUp}
                    type={'LIST'}
                    isCloseAction={false}
                />
            </div>
        </Modal>
    );
};
