import React, { useState } from 'react';
import './FollowUpForm.scss';
import { Button } from '@vacasa/react-components-lib';
import { useAddFollowUpMutation } from '../../services';
import { Icons } from '../../assests/icons';
import { useLoading } from '../../contexts/LoadingContext';

interface FollowUpFormProps {
    onHide: () => void;
    maintenanceTicketId: number;
    showForm: boolean;
    openForm?: () => void;
    type: string;
    disabled: boolean;
    isCloseAction: boolean;
    disabledAddBtn?: boolean;
}

export const FollowUpForm: React.FC<FollowUpFormProps> = (props: FollowUpFormProps) => {
    const { onHide, maintenanceTicketId, showForm, openForm, type, disabled, isCloseAction, disabledAddBtn } = props;
    const [noteValue, setNoteValue] = useState<string>('');
    const [addFollowUp, { isLoading, isError }] = useAddFollowUpMutation();
    const { setLoadingConfig } = useLoading();

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setNoteValue(value);
    };

    const handleAddFollowUp = async () => {
        setLoadingConfig(true);
        await addFollowUp({ id: maintenanceTicketId, note: noteValue.trim(), tag: type }).unwrap();
        setNoteValue('');
        onHide();
        setLoadingConfig(false);
    };

    if (!showForm) {
        return (
            <div id={'div-show-form'} className={'d-flex justify-content-center mt-2'}>
                <Button disabled={isCloseAction || disabledAddBtn} variant={'info'} onClick={openForm}>
                    {'Add Follow Up'}
                </Button>
            </div>
        );
    }

    return (
        <div>
            {disabled ? (
                <div className={'d-flex justify-content-center text-center mt-2 mb-2'}>
                    <span id={'span-disabled-follow-up'}>
                        It is not possible to add more follow up to this ticket. Once the 28 days have expired, this ticket must be
                        resolved.
                    </span>
                </div>
            ) : (
                <div>
                    <textarea
                        id={'text-area-add-follow-up-note'}
                        maxLength={255}
                        minLength={1}
                        value={noteValue}
                        onChange={handleTextChange}
                        placeholder={'Add follow up note'}
                        disabled={isLoading}
                    />

                    <div className={''}>
                        <span>{`${noteValue.length}/${255}`}</span>
                    </div>
                </div>
            )}
            {isError && (
                <div>
                    <span className={'error'}>error adding follow up</span>
                </div>
            )}

            <div>
                <div className="text-editor-actions d-flex justify-content-end">
                    <Button disabled={isLoading} onClick={onHide} variant="info" customClass={'cancel-btn'}>
                        {'Cancel'}
                    </Button>
                    {!disabled && (
                        <Button disabled={isLoading} onClick={handleAddFollowUp} variant={'secondary'}>
                            {isLoading && <Icons.Spinner className="mr-2" />}
                            {'Add'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
