import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, RadioButton, Button } from '@vacasa/react-components-lib';
import './MaintenanceTicketForm.scss';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { DescriptionTicketTemplates } from './TemplateDescription';
import { Icons } from '../../assests/icons';
import { mapSeverityToString, ResponseType, Severity } from '@reviews/interfaces';
import * as _ from 'lodash';
import { getAutocompleteTemplateText } from './MaintenanceTicketForm.utils';
import { addDays } from 'date-fns';

export interface MaintenanceTicketFormValues {
    assigned_to: number;
    title: string;
    completion_date: Date;
    severity: number;
    description: string;
    response_type: string;
}
export interface MaintenanceTicketFormProps {
    LOMName: string;
    LOMEmail: string;
    LOMId: number;
    reviewId: number;
    isLoading: boolean;
    isError: any;
    onCreate: (values: any, type: string) => void;
    overall?: string;
    checkOutDate: string;
}

const DefaultTitle: { [key: string]: string } = {
    [ResponseType.Feedback_FYI]: 'Guest Feedback: Admin, (Insert what was reported; broken item, repair needed, missing amenity)',
    [ResponseType.Response_needed]: 'Review Response Needed - Admin',
};

export const MaintenanceTicketForm: React.FC<MaintenanceTicketFormProps> = (props) => {
    const [maintenanceType, setMaintenanceType] = useState<string>('');
    const formRef = useRef<FormikProps<MaintenanceTicketFormValues>>(null);

    const { LOMId, LOMName, LOMEmail, reviewId, overall, isLoading, isError, onCreate, checkOutDate } = props;
    const { REACT_APP_ADMIN_URL } = process.env;
    const initialFormSchema = {
        title: Yup.string()
            .required()
            .test('validate-spaces', 'title is required', (value) => value!.trim().length > 0),
        severity: Yup.number().required(),
        description: Yup.string().max(2000),
        assigned_to: Yup.number().required(),
        completion_date: Yup.date().required(),
    };

    const initialValues: MaintenanceTicketFormValues = {
        assigned_to: LOMId,
        title: DefaultTitle[maintenanceType],
        completion_date: addDays(new Date(), 3),
        severity: Severity.Medium,
        description: '',
        response_type: ResponseType.Response_needed,
    };
    const [formSchema] = useState(Yup.object().shape(initialFormSchema));

    useEffect(() => {
        const currenTemplate = DescriptionTicketTemplates[maintenanceType] ?? '';
        const newDescription = getAutocompleteTemplateText(
            currenTemplate,
            LOMName,
            overall ?? ' ',
            REACT_APP_ADMIN_URL ?? ' ',
            reviewId,
            2000,
            checkOutDate
        );
        formRef.current?.setFieldValue('title', DefaultTitle[maintenanceType]);
        formRef.current?.setFieldValue('description', newDescription);
        formRef.current?.validateForm();
    }, [maintenanceType, overall]);

    const validateForm = async (values: MaintenanceTicketFormValues): Promise<any> => {
        let validationErrors: { [key: string]: string } = {};

        try {
            await formSchema.validate(values, { abortEarly: false });
        } catch (error: any) {
            (error.inner || []).forEach((e: Yup.ValidationError) => {
                if (e.path) {
                    validationErrors[e.path] = e.message;
                }
            }, validationErrors);
        }

        if (_.keys(validationErrors).length) {
            return validationErrors;
        }
    };

    if (isLoading) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }
    return (
        <div id={'maintenance-ticket-form'} className="container">
            <div className="">
                <div>
                    <label className="  type-body" htmlFor="title">
                        Ticket Type
                    </label>
                    <div className="d-flex  justify-content-between">
                        <div className={'body-small-regular'}>
                            <RadioButton
                                checked={maintenanceType}
                                label="Response Needed"
                                setter={setMaintenanceType}
                                value={ResponseType.Response_needed}
                                disabled={false}
                            />
                        </div>
                        <div className={'body-small-regular'}>
                            <RadioButton
                                checked={maintenanceType}
                                label="Feedback FYI"
                                setter={setMaintenanceType}
                                value={ResponseType.Feedback_FYI}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={(values) => onCreate(values, maintenanceType)}
                    validate={validateForm}
                    validateOnMount={true}
                >
                    {(props) => {
                        const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue } = props;
                        return (
                            <form className={'body-small-regular'} onSubmit={handleSubmit}>
                                {maintenanceType !== '' && (
                                    <div>
                                        <div className="row mt-2 mb-2">
                                            <div className="col-12 mr-1">
                                                <label className="mt-2  type-body" htmlFor="title">
                                                    Title
                                                </label>
                                                <input
                                                    id="title"
                                                    aria-required="true"
                                                    className={`w-100 form-control body-small-regular ${
                                                        errors.title && touched.title ? 'invalid' : ''
                                                    }`}
                                                    type="text"
                                                    name="title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.title}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-12">
                                                <label className="mt-2  type-body" htmlFor="title">
                                                    Severity
                                                </label>
                                                <select
                                                    id="severity"
                                                    value={values.severity}
                                                    aria-required="true"
                                                    name="severity"
                                                    onChange={(e) => setFieldValue('severity', +e.target.value)}
                                                    onBlur={handleBlur}
                                                    className={`form-select  body-small-regular ${
                                                        errors.severity && touched.severity ? 'invalid' : ''
                                                    }`}
                                                >
                                                    {Object.keys(mapSeverityToString).map((k) => (
                                                        <option value={+k}>{mapSeverityToString[+k]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12 mr-1">
                                                <label className="mt-2" htmlFor="title">
                                                    Assigned to
                                                </label>
                                                <div>
                                                    <span className={'body-small-bold'}>{`${LOMName} (${LOMEmail})`}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12 mr-1">
                                                <label className="mt-2" htmlFor="title">
                                                    Due date
                                                </label>

                                                <DatePicker
                                                    value={values.completion_date}
                                                    onChange={(value) => setFieldValue('completion_date', value)}
                                                    onBlur={handleBlur}
                                                    variant="dialog"
                                                    disabled={false}
                                                    id="completion_date"
                                                    name="completion_date"
                                                    minDate={new Date()}
                                                    className={`${
                                                        errors.completion_date && touched.completion_date
                                                            ? ' body-small-regular due-date invalid'
                                                            : '  body-small-regular due-date'
                                                    }`}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3 mb-3">
                                            <div>
                                                <label>Ticket Details</label>
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    maxLength={2000}
                                                    className={`form-control  body-small-regular textarea-maintenance col-12 
                                                    ${errors.description && touched.description ? 'invalid' : ''}`}
                                                />
                                                {values.description && (
                                                    <div>
                                                        <span className={' body-small-regular'}>{`${values.description.length}/2000`}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {isError && <span className={'error-msg'}>error creating ticket</span>}
                                        <div className="row text-end mb-3">
                                            <div>
                                                <Button type="submit" onClick={() => {}} variant={'secondary'} disabled={!isValid}>
                                                    {isLoading && <Icons.Spinner className="mr-2" />}
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};
