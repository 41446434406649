import * as _ from 'lodash';
import { SearchParams } from '../../store/flow';

export enum SearchTypes {
    REVIEW_ID = '[review.review_id]',
    ADMIN_RESERVATION_ID = '[admin_reservation_id]',
    ADMIN_UNIT_ID = '[unit.admin_unit_id]',
    UNIT_CODE = '[unit.unit_code]',
    ASSIGNED_TO = '[assigned_to_id]',
}

export const SearchList = [
    { value: SearchTypes.REVIEW_ID, display: 'Review ID' },
    { value: SearchTypes.ADMIN_RESERVATION_ID, display: 'Reservation ID' },
    { value: SearchTypes.ADMIN_UNIT_ID, display: 'Unit ID' },
    { value: SearchTypes.UNIT_CODE, display: 'Unit Code' },
    { value: SearchTypes.ASSIGNED_TO, display: 'Assigned To' },
];

export enum SearchCriteriaParam {
    REVIEW_ID = 'ReviewID',
    ADMIN_RESERVATION_ID = 'ReservationID',
    ADMIN_UNIT_ID = 'UnitID',
    UNIT_CODE = 'UnitCode',
    ASSIGNED_TO = 'AssignedID',
}

const CriteriaParamsToSearchType = {
    [SearchCriteriaParam.REVIEW_ID]: SearchTypes.REVIEW_ID,
    [SearchCriteriaParam.ADMIN_RESERVATION_ID]: SearchTypes.ADMIN_RESERVATION_ID,
    [SearchCriteriaParam.ADMIN_UNIT_ID]: SearchTypes.ADMIN_UNIT_ID,
    [SearchCriteriaParam.UNIT_CODE]: SearchTypes.UNIT_CODE,
    [SearchCriteriaParam.ASSIGNED_TO]: SearchTypes.ASSIGNED_TO,
};

export const processInputText = (type: SearchTypes, text: string): { isValid: boolean; text: string | number } => {
    switch (type) {
        case SearchTypes.REVIEW_ID:
        case SearchTypes.ADMIN_UNIT_ID:
        case SearchTypes.ADMIN_RESERVATION_ID:
            const num = text.match(/^[0-9]+$/);
            return { isValid: !_.isNil(num), text };

        case SearchTypes.UNIT_CODE:
            return { isValid: true, text: text.toUpperCase() };

        default:
            return { isValid: false, text: '' };
    }
};

export const getCriteriaParamsByCategory = (category: SearchTypes) => {
    const arr = _.entries(CriteriaParamsToSearchType).find(([_key, value]) => value === category);
    return arr ? arr[0] : '';
};

export const getValidSearchParams = (criteria: string | null, value: string | null): SearchParams => {
    const searchParams: SearchParams = {
        haveToLookFor: true,
        criteria: {
            value: '' as SearchTypes,
            isValid: false,
        },
        searchValue: {
            value: '',
            isValid: false,
        },
    };

    if (_.isNil(criteria) || _.isNil(value)) {
        return searchParams;
    }

    const isValidCriteria = !_.isNil(CriteriaParamsToSearchType[criteria as SearchCriteriaParam]);

    if (!isValidCriteria) {
        return searchParams;
    }

    const categoryFilter = CriteriaParamsToSearchType[criteria as SearchCriteriaParam];

    searchParams.criteria = {
        value: categoryFilter,
        isValid: true,
    };

    const objValue = processInputText(categoryFilter, value);

    if (!objValue.isValid) {
        return searchParams;
    }

    searchParams.searchValue = {
        value: objValue.text,
        isValid: true,
    };

    return searchParams;
};
