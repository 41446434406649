import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { withTooltip } from '@vacasa/react-components-lib';

interface CollapseTextProps {
    text: string;
    message: string;
    maxLength: number;
}

export const CollapseText: React.FC<CollapseTextProps> = (props: CollapseTextProps) => {
    const { text, message, maxLength } = props;
    const [isShowingCollapsedText, setIsShowingCollapsedText] = useState<boolean>(false);

    const collapseAt = maxLength || Number.MAX_SAFE_INTEGER;

    const shouldCollapse = text.length > collapseAt;
    const trimmedText = text.substring(0, collapseAt);
    const remainingText = text.substring(collapseAt, text.length);

    return (
        <>
            {withTooltip(
                message,
                <p className={`collapsed-text`}>
                    {trimmedText}
                    <Collapse in={isShowingCollapsedText}>
                        <span>{remainingText}</span>
                    </Collapse>
                    {shouldCollapse && (
                        <span className="collapsed-text-action" onClick={() => setIsShowingCollapsedText(!isShowingCollapsedText)}>
                            {isShowingCollapsedText ? ' ...less' : ' ...more'}
                        </span>
                    )}
                </p>
            )}
        </>
    );
};
