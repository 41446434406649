import { ResponseType } from '@reviews/interfaces';

export const DescriptionTicketTemplates: { [key: string]: string } = {
    [ResponseType.Response_needed]: `Hi (ASSIGNEE NAME),
     
We received a review and would like to get your feedback on a few specific concerns before we post a manager response.

Please address all of the following points:
-
-
-

Full review:
"(REVIEW)"

Thank you in advance for your hard work!

*****Please reassign to Lorajean Harryman so we can get a manager response posted within 3 days.******

Thank you!
    `,
    [ResponseType.Feedback_FYI]: `This ticket is an FYI regarding a guest-reported issue that we had no prior record of. Please do not assign this to Reputation Management. No response is needed, and you may close it after review.

- Date of Check-out: (DATE)
- Issue or Missing Amenity Reported:
- Follow-up Requested by the Guest:
- Full Text Provided by the Guest: “(REVIEW)”

We felt this information was important enough to pass along for your review. If you need assistance contacting this guest or offering compensation, please post in the #ops-gx Slack Channel. For review-related questions, email us at repmanagement@vacasa.com, as this ticket will not be monitored.
    `,
};
