import React from 'react';
import {
    BaseUser,
    FollowUpData,
    HistoryAction,
    MaintenanceTicket,
    mapSeverityToString,
    mapStatusIdToString,
    mapTypeToString,
    ResponseType,
    SurveyHistory,
} from '@reviews/interfaces';
import './MaintenanceTicketDetail.scss';
import { format } from 'date-fns';
import { Icon } from '@vacasa/react-components-lib';
import HistoryTimeLine from '../TimeLine/HistoryTimeLine';
import { mapHistoryToTimeLine } from '../HistoryForm/HistoryForm.utils';
import * as _ from 'lodash';
import { openInNewTab } from '../../utils/shared.utils';

export interface MaintenanceTicketDetailProps {
    ticketDetail: MaintenanceTicket;
    created_by: BaseUser | null;
    children: JSX.Element;
    surveyHistory?: Array<SurveyHistory>;
}

export const MaintenanceTicketDetail: React.FC<MaintenanceTicketDetailProps> = (props) => {
    const {
        ticketDetail: {
            admin_ticket_id,
            title,
            status,
            severity,
            created_at,
            updated_at,
            description,
            completion_date,
            assigned_to,
            follow_up_status,
            follow_up_date,
            response_type,
        },
        created_by,
        children,
        surveyHistory,
    } = props;
    const { REACT_APP_ADMIN_URL } = process.env;

    const mtHistory = _.filter(surveyHistory, (h) => h.action_type === HistoryAction.FOLLOW_UP);
    const events = mapHistoryToTimeLine(mtHistory);
    return (
        <div className={'container body-small-regular mt-detail-container'}>
            <div className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                    <span className={'body-small-bold'}>Ticket ID: </span>
                    <span
                        onClick={() => openInNewTab(`tickets/view?TicketID=${admin_ticket_id}&Maintenance=1`, REACT_APP_ADMIN_URL)}
                        className={'link-style'}
                    >
                        {`${admin_ticket_id}`}
                        <Icon.ExternalLink width={20} />
                    </span>
                </div>
                <div className={'d-flex'}>
                    <span className={'body-small-bold'}> Assigned To :</span>
                    <span onClick={() => openInNewTab(`tickets/index?UserID=${assigned_to}`, REACT_APP_ADMIN_URL)} className={'link-style'}>
                        {`${assigned_to}`}
                        <Icon.ExternalLink width={20} />
                    </span>
                </div>
            </div>

            <div className={'mt-2'}>
                <div className={'detail'}>
                    <span className={'d-flex align-items-center'}>
                        <Icon.Circle width={20} />
                        <span className={'body-small-bold'}>Status:</span>
                        {`  ${mapStatusIdToString[status]}`}
                    </span>
                </div>
                <div className={'detail'}>
                    <span className={'d-flex align-items-center'}>
                        <Icon.AlertCircle width={20} />
                        <span className={'body-small-bold'}>Severity:</span>
                        {`  ${mapSeverityToString[severity]}`}
                    </span>
                </div>
                <div className={'detail'}>
                    <span className={'d-flex align-items-center'}>
                        <Icon.Circle width={20} />
                        <span className={'body-small-bold'}>Type:</span>
                        {`  ${mapTypeToString[response_type]}`}
                    </span>
                </div>
            </div>
            <hr />

            <div className={' mt-2'}>
                <div className={'detail'}>
                    <span className={'d-flex align-items-center'}>
                        <Icon.Calendar width={20} />
                        {'Created at ' + format(new Date(created_at), 'yyyy-MM-dd HH:mm') + ` by  ${created_by?.name ?? ''}`}
                    </span>
                </div>
                <div className={'detail'}>
                    <span className={'d-flex align-items-center'}>
                        <Icon.CalendarX width={20} />
                        {'Due Date: ' + format(new Date(completion_date), 'yyyy-MM-dd HH:mm')}
                    </span>
                </div>
                <div className={'detail'}>
                    {updated_at && (
                        <span className={'d-flex align-items-center'}>
                            <Icon.Clock width={20} />
                            {`Last update: ${format(new Date(updated_at), 'yyyy-MM-dd HH:mm')} `}
                        </span>
                    )}
                </div>
            </div>

            <hr />

            {response_type === ResponseType.Response_needed && (
                <>
                    <div className={' mt-2'}>
                        <div>
                            <div>
                                <span className={'body-small-bold'}>Follow Up Status: </span>
                                <span>{FollowUpData[follow_up_status]?.name || ''}</span>
                            </div>

                            <div>
                                <span className={'body-small-bold'}>Next Follow Up: </span>
                                <span>{format(new Date(follow_up_date), 'yyyy-MM-dd')}</span>
                            </div>
                        </div>

                        <div>{children}</div>
                    </div>
                    <hr />
                </>
            )}

            <div className={'mt-2'}>
                <div>
                    <span className={'body-small-bold'}>Title</span>
                </div>
                <span>{title}</span>
            </div>
            <div className={'mt-2'}>
                <div>
                    <span className={'body-small-bold'}>Description</span>
                </div>
                <span className={'mt-description'}>{description ? `${description}` : 'Without description'}</span>
            </div>

            <hr />
            {response_type === ResponseType.Response_needed && (
                <div className={'mt-3 '}>
                    <div>
                        <span className={'body-small-bold'}>Follow Up History</span>
                    </div>
                    <HistoryTimeLine events={events} />
                </div>
            )}
        </div>
    );
};
