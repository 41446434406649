import React, { useEffect } from 'react';
import { Badge } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import './ReviewCountByStars.scss';
import { Icons } from '../../assests/icons';
import { useSelector } from 'react-redux';
import { Store } from '../../store';

interface ReviewsByStarsPillsProps {
    count: { [stars: string]: number };
    handleFilter: (filter: { filters: { [key: string]: string } }, cleanFilters?: boolean) => void;
}

export const ReviewCountByStars: React.FC<ReviewsByStarsPillsProps> = (props) => {
    const flow = useSelector((state: Store) => state.flow);

    const [action, setAction] = React.useState<boolean>(false);
    let newStartFilter: { [key: number]: { active: boolean } } = {};
    _.forOwn(flow.startFilter, (value, key) => (newStartFilter[+key] = { ...value }));
    const [filtersMap, setFiltersMap] = React.useState<{ [key: number]: { active: boolean } }>(newStartFilter);
    const { count, handleFilter } = props;

    useEffect(() => {
        _.forOwn(flow.startFilter, (value, key) => (newStartFilter[+key] = { ...value }));
        setFiltersMap(newStartFilter);
    }, [flow.startFilter]);

    useEffect(() => {
        let filters = flow.request.filters;
        let ratingFilters: string = _.reduce(
            filtersMap,
            (acc, curr, key) => {
                if (curr.active) {
                    acc.push(key);
                }
                return acc;
            },
            [] as Array<string>
        ).join(',');
        if (_.isEmpty(ratingFilters) && filters?.hasOwnProperty('[review.rating][in]')) {
            filters = _.reduce(
                filters,
                (acc: { [key: string]: any }, curr, key: string) => {
                    if (key !== '[review.rating][in]') {
                        acc[`${key}`] = curr;
                    }
                    return acc;
                },
                {}
            );
            handleFilter({ filters }, true);
        }
        if (_.isEmpty(ratingFilters)) {
            return;
        }
        handleFilter({ filters: { '[review.rating][in]': ratingFilters } });
    }, [action]);

    const handlerOnClick = (rating: number, _event: any) => {
        _.forOwn(filtersMap, (_object, key) => {
            const numberKey = _.toNumber(key);
            const badge = document.getElementById(`badge-${numberKey}`);
            if (numberKey !== rating) {
                badge!.className = 'badge-container';
                filtersMap[numberKey].active = false;
            } else {
                filtersMap[numberKey].active = !filtersMap[numberKey].active;
                badge!.className = filtersMap[numberKey].active ? 'badge-container active' : 'badge-container';
            }
        });
        setFiltersMap(filtersMap);
        setAction(!action);
    };

    return (
        <React.Fragment>
            <div className="count-pills">
                <Icons.Star className="star-icon" fill={'#FAB60F'} />
                {_.keys(count).map((stars, i) => (
                    <div
                        className={`badge-container ${filtersMap[i + 1].active && 'active'}`}
                        key={i}
                        id={`badge-${i + 1}`}
                        onClick={(e) => handlerOnClick(i + 1, e)}
                    >
                        <Badge
                            disabled={filtersMap[i + 1].active}
                            closeButton={false}
                            isPill={true}
                            text={`${stars} (${count[stars]})`}
                            outline={false}
                            variant="warning"
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};
