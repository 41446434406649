import React, { useEffect, useState } from 'react';
import { Icons } from '../../assests/icons';
import { MaintenanceTicketDetail } from '../MaintenanceTicketDetail/MaintenanceTicketDetail';
import { MaintenanceTicketForm, MaintenanceTicketFormValues } from '../MaintenanceTicketForm/MaitenanceTicketForm';
import { Icon } from '@vacasa/react-components-lib';
import { mapToMaintenanceTicketData } from './MaintenanceTicketContainer.utils';
import './MaintenanceTicketContainer.scss';
import { FollowUpForm } from '../FollowUpForm/FollowUpForm';
import { DetailedError, ErrorTypes, FollowUpStatus, ReviewStatus, Status, SurveyHistory, UnitData } from '@reviews/interfaces';
import { useCreateReviewMaintenanceTicketMutation, useRefreshMaintenanceTicketMutation } from '../../services';
import { getDiffTimeInSeg } from '../../utils/shared.utils';
import { addDays, format } from 'date-fns';
import * as _ from 'lodash';
import { useLoading } from '../../contexts/LoadingContext';

export interface MaintenanceTicketContainerProps {
    survey_response_id: number;
    UnitData: UnitData | undefined;
    reviewId: number;
    isClose?: boolean;
    overall: string;
    isEmployee: boolean;
    ticketData: any;
    validationAssign: boolean;
    history: SurveyHistory[];
    isLoadingReview: boolean;
    reviewStatus: ReviewStatus;
    openRefreshModal: () => void;
    startTime: number;
    setStartTime: (value: number) => void;
    lastNight: string;
}

export const MaintenanceTicketContainer: React.FC<MaintenanceTicketContainerProps> = (props) => {
    const {
        UnitData,
        reviewId,
        isClose,
        overall,
        history,
        ticketData,
        isEmployee,
        validationAssign,
        isLoadingReview,
        openRefreshModal,
        reviewStatus,
        startTime,
        setStartTime,
        lastNight,
    } = props;

    const { data, error, isLoading } = ticketData;

    const { maintenanceTicketData, createdByData } = mapToMaintenanceTicketData(data);
    const [createMaintenanceTicket, { isLoading: isLoadingCreate, error: errorCreate }] = useCreateReviewMaintenanceTicketMutation();
    const [refreshTicket] = useRefreshMaintenanceTicketMutation();
    const [showFollowUp, setShowFollowUp] = useState<boolean>(false);
    const { setLoadingConfig } = useLoading();

    const LOMName = `${UnitData?.local_operations_manager?.first_name} ${UnitData?.local_operations_manager?.last_name}` ?? '';
    const LOMEmail = UnitData?.local_operations_manager?.email ?? '';
    const LOMId = UnitData?.local_operations_manager?.id ?? 0;

    const lastNightDate = new Date(lastNight);
    const checkOutDate = !_.isEmpty(lastNight) ? format(addDays(lastNightDate, 1), 'yyyy-MM-dd') : '';

    useEffect(() => {
        if (
            maintenanceTicketData &&
            maintenanceTicketData.status != Status.Resolved_Dismissed &&
            reviewStatus != ReviewStatus.RESPONSE_REQUIRED &&
            !isEmployee
        ) {
            refreshTicket({ id: maintenanceTicketData.maintenance_ticket_id });
        }
    }, []);

    const handleSubmit = async (values: MaintenanceTicketFormValues, type: string) => {
        values.description = values.description.trim();
        values.response_type = type;
        setLoadingConfig(true, false);
        try {
            await createMaintenanceTicket({
                data: values,
                id: reviewId,
                validate_assigned: validationAssign,
                time: getDiffTimeInSeg(Date.now(), startTime),
            }).unwrap();
            setStartTime(Date.now());
        } catch (e) {
            const kindError = (e as DetailedError)?.code || ErrorTypes.UNHANDLED;
            const isAssignError = kindError === ErrorTypes.ASSIGNED;
            if (isAssignError) {
                openRefreshModal();
            }
        } finally {
            setLoadingConfig(false);
        }
    };

    if (isLoading || isLoadingReview) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <h1>An error occurred:</h1>
                <div>{JSON.stringify(error)}</div>
            </div>
        );
    }

    const isDisabledFollowUp = maintenanceTicketData?.follow_up_status === FollowUpStatus.Resolution;

    return (
        <div className={'body-small-regular'}>
            {maintenanceTicketData ? (
                <MaintenanceTicketDetail ticketDetail={maintenanceTicketData} created_by={createdByData} surveyHistory={history}>
                    <FollowUpForm
                        onHide={() => setShowFollowUp(false)}
                        showForm={showFollowUp}
                        openForm={() => setShowFollowUp(true)}
                        maintenanceTicketId={maintenanceTicketData.maintenance_ticket_id}
                        disabled={isDisabledFollowUp}
                        disabledAddBtn={isEmployee}
                        type={'DETAILS'}
                        isCloseAction={isClose ?? false}
                    />
                </MaintenanceTicketDetail>
            ) : isClose ? (
                <div className={'mt-close'}>
                    <div className={'icon'}>
                        <Icon.Lock width={60} />
                    </div>
                    <div className={'msg'}>{'This review has already been processed, you can reopen the review in process review.'}</div>
                </div>
            ) : (
                <MaintenanceTicketForm
                    overall={overall}
                    LOMName={LOMName}
                    LOMEmail={LOMEmail}
                    LOMId={LOMId}
                    reviewId={reviewId}
                    isLoading={isLoadingCreate}
                    isError={errorCreate}
                    onCreate={handleSubmit}
                    checkOutDate={checkOutDate}
                />
            )}
        </div>
    );
};

export default MaintenanceTicketContainer;
