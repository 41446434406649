import React from 'react';
import './SideBarContainer.scss';
import { Icon, withTooltip } from '@vacasa/react-components-lib';

export interface SidebarContainerProps {
    children: JSX.Element;
    onHide: () => void;
    title: string;
    baseStyle: string;
    isClose: boolean;
}

export const SideBarContainer: React.FC<SidebarContainerProps> = (props) => {
    const { children, title, onHide, baseStyle, isClose } = props;
    return (
        <div className={baseStyle}>
            <div className={'body-small-regular container-sidebar'}>
                <div className="sidebar-title">
                    <div onClick={onHide} className={'btn-right d-flex'}>
                        <Icon.ChevronRight height={24} width={24} />
                    </div>
                    <div className={'body-small-bold title'}>
                        <span>{title}</span>
                        {isClose &&
                            withTooltip(
                                'This review has already been processed, you can reopen the review in process review',
                                <span className={'icon-close'}>
                                    <Icon.Lock />
                                </span>
                            )}
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};
