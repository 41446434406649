import React from 'react';
import Timeline from '@mui/lab/Timeline';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import './HistoryTimeLine.scss';
import { HistoryAction, HistoryPayload } from '@reviews/interfaces';
import { CollapseText } from './CollapseText';

export interface EventTimeLine {
    title?: string;
    titleBold?: string;
    subtitle?: string;
    icon?: JSX.Element;
    payload: HistoryPayload;
    action: HistoryAction;
}

export interface HistoryTimeLineProps {
    events: Array<EventTimeLine>;
}

export const HistoryTimeLine: React.FC<HistoryTimeLineProps> = ({ events }) => {
    return (
        <Timeline
            id={'history-timeline'}
            sx={{
                '& .MuiTimelineItem-root:before': {
                    display: 'none',
                },
            }}
        >
            {events.map((e, index) => (
                <TimelineItem key={index}>
                    <TimelineSeparator>
                        <TimelineDot className={'icon-point'}>{e.icon}</TimelineDot>

                        {index !== events.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <div className={'d-flex flex-column title-data'}>
                            <span className={'body-small-bold '}>{e.titleBold ?? ''}</span>
                            <span className={'body-small-bold sub-title mb-1'}>{e.subtitle ?? ''}</span>
                            {e.action === HistoryAction.EDITED ? (
                                <span className={'body-small-regular title'}>
                                    <CollapseText text={(e?.payload?.old as string) ?? ''} message={'old value'} maxLength={50} />
                                    <CollapseText text={(e?.payload?.new as string) ?? ''} message={'new value'} maxLength={50} />
                                </span>
                            ) : (
                                <span className={'body-small-regular title'}>{e.title ?? ''}</span>
                            )}
                        </div>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default HistoryTimeLine;
