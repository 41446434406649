export const getAutocompleteTemplateText = (
    text: string,
    userName: string,
    overall: string,
    urlAdmin: string,
    reviewId: number,
    maxLength: number,
    checkOutDate: string
): string => {
    const nameReplaceString = '(ASSIGNEE NAME)';
    const dateReplaceString = '(DATE)';
    const reviewReplaceString = '(REVIEW)';

    let newTemplateText = text.replace(nameReplaceString, userName);

    newTemplateText = newTemplateText.replace(dateReplaceString, checkOutDate);

    const lenTemplate = newTemplateText.length;

    const lenOverall = overall.length;

    const totalLength = lenTemplate + lenOverall;

    if (totalLength > maxLength) {
        let auxText = `.......See the full review in ${urlAdmin}review/${reviewId}`;
        const difLength = maxLength - (lenTemplate + auxText.length - reviewReplaceString.length);
        const subStringOverall = overall.substring(0, difLength);
        newTemplateText = newTemplateText.replace(reviewReplaceString, subStringOverall + auxText);
        return newTemplateText;
    } else {
        newTemplateText = newTemplateText.replace(reviewReplaceString, overall);

        return newTemplateText;
    }
};
