import { ImplicitFlow, ThemeProvider } from '@vacasa/react-components-lib';
import defaultTheme from '@vacasa/react-components-lib/lib/themes/default';
import Container from '@mui/material/Container';
import { Router } from './Router';
import { Provider } from 'react-redux';
import { store } from './store';
import { Configuration } from './Configuration';
import './index.scss';
import { AuthRole } from './components/AuthRole/AuthRole';
import { useEffect, useState } from 'react';
import { getLocalUserContext, loadUserContext } from './contexts/userContext';
import OptimizelyService from './services/optimizelyService';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { LoadingProvider } from './contexts/LoadingContext';
import { nanoid } from '@reduxjs/toolkit';
import { LoadingOverlay } from './components/LoadingOverlay/LoadingOverlay';

export function App() {
    const AppContainer = () => {
        const userContext = getLocalUserContext();
        const [skip, setSkip] = useState<boolean>(false);
        const optimizely = OptimizelyService.getOptimizelyInstance();

        useEffect(() => {
            if (!skip) {
                loadUserContext();
                setSkip(true);
            }
        }, [userContext]);

        return (
            <>
                <OptimizelyProvider optimizely={optimizely as any} userId={nanoid()}>
                    <AuthRole userContext={userContext} />
                    <ThemeProvider theme={defaultTheme}>
                        <Container maxWidth={false} className="body-regular app-container">
                            <h1>Reviews Manager</h1>
                            <Router />
                        </Container>
                    </ThemeProvider>
                </OptimizelyProvider>
            </>
        );
    };

    return (
        <ImplicitFlow {...Configuration.getIDPConfigurations()}>
            <Provider store={store}>
                <LoadingProvider>
                    <LoadingOverlay />
                    <AppContainer />
                </LoadingProvider>
            </Provider>
        </ImplicitFlow>
    );
}
