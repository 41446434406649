import { setUserLoggedContext } from '../../contexts/userContext';
import { useLazyGetUserRoleQuery } from '../../services';
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import defaultLogger from '@reviews/frontend/build/src/logging/ddLogger';

interface AuthRoleProps {
    userContext: { email: string };
}

export const AuthRole: React.FC<AuthRoleProps> = (props) => {
    const { userContext } = props;

    const [skip, setSkip] = useState<boolean>(false);
    const [getUserRole] = useLazyGetUserRoleQuery();

    useEffect(() => {
        (async () => {
            if (!_.isNil(userContext) && !skip) {
                const { data: userLogged } = await getUserRole(userContext);
                setUserLoggedContext(userLogged?.data.attributes);
                setSkip(true);
                defaultLogger.info(`User ${userLogged?.data.attributes.user_id} successfully authenticated`, {
                    admin_user_id: userLogged?.data.attributes.admin_user_id,
                    name: userLogged?.data.attributes.name,
                    role: userLogged?.data.attributes.role,
                });
            }
        })();
    }, [userContext]);

    return <></>;
};
