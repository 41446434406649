import React, { useState } from 'react';
import HistoryTimeLine from '../TimeLine/HistoryTimeLine';
import { Button, Icon } from '@vacasa/react-components-lib';
import './HistoryForm.scss';
import { Roles, SurveyHistory } from '@reviews/interfaces';
import { mapHistoryToTimeLine } from './HistoryForm.utils';
import { TextEditor } from '../TextBox/TextEditor';
import { Icons } from '../../assests/icons';
import { useAddHistoryNoteMutation } from '../../services';
import { getUserLoggedContext } from '../../contexts/userContext';
import { useLoading } from '../../contexts/LoadingContext';

export interface HistoryFormProps {
    surveyHistory: Array<SurveyHistory>;
    surveyId: number;
    isLoadingReview?: boolean;
    disableAction?: boolean;
}

export const HistoryForm: React.FC<HistoryFormProps> = (props) => {
    const isEmployee = getUserLoggedContext() && getUserLoggedContext().role === Roles.EMPLOYEE;
    const [showNoteForm, setShowNoteForm] = useState<boolean>(false);
    const [addNewNote, { isLoading, error }] = useAddHistoryNoteMutation();
    //TODO:validate disableAction
    const { surveyHistory, surveyId, isLoadingReview, disableAction } = props;
    const { setLoadingConfig } = useLoading();

    const events = mapHistoryToTimeLine(surveyHistory);
    const handleOnChangeNote = async (newValue: string, _oldValue?: string): Promise<void> => {
        if (surveyId && newValue) {
            setLoadingConfig(true);
            await addNewNote({ note: newValue, id: +surveyId }).unwrap();
            setLoadingConfig(false);
        }
        setShowNoteForm(false);
    };

    if (isLoading || isLoadingReview) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }
    return (
        <div id={'history-form'} className={'container'}>
            <div className={'m-2'}>
                {showNoteForm && (
                    <TextEditor
                        placeHolder={'Add new note here'}
                        text={''}
                        onSave={handleOnChangeNote}
                        onCancel={() => setShowNoteForm(false)}
                        max={250}
                        min={1}
                        labelSuccessBtn={'Add'}
                    />
                )}
            </div>
            {error && (
                <div className={'error-msg'}>
                    <span>Error adding a note</span>
                </div>
            )}
            <div className={'text-end m-2'}>
                <div className={'mr-0'}>
                    {!showNoteForm && !isEmployee && !disableAction && (
                        <Button
                            test-id={'add-btn'}
                            variant="info"
                            type={'submit'}
                            onClick={() => setShowNoteForm(true)}
                            customClass={'note-button'}
                        >
                            <Icon.Plus />
                            Add Note
                        </Button>
                    )}
                </div>
            </div>

            <div className={''}>
                <HistoryTimeLine events={events} />
            </div>
        </div>
    );
};

export default HistoryForm;
