import { Modal } from '@vacasa/react-components-lib';
import { AutoCompleteUsers } from '../AutoCompleteUsers/AutoCompletUsers';
import React, { useState } from 'react';
import { ResponseType, useAssignUserMutation, useGetUsersQuery } from '../../services';
import * as _ from 'lodash';
import { Spinner } from '../../assests/icons/icons';
import { Button } from '@vacasa/react-components-lib';
import { useSelector } from 'react-redux';
import { BaseUser } from '@reviews/interfaces';
import './AssignModal.scss';
import { Store } from '../../store';
import { useLoading } from '../../contexts/LoadingContext';

interface AssignModalProps {
    showModal: boolean;
    onHide: () => void;
    assignUser: BaseUser | null | undefined;
    reviewId: number;
    onRefreshPage: () => void;
}

export const AssignModal: React.FC<AssignModalProps> = (props) => {
    const { onHide, showModal, reviewId, assignUser, onRefreshPage } = props;
    const flow = useSelector((state: Store) => state.flow);

    const currentUser = flow?.user;
    const validationAssign = _.isNil(assignUser);

    const [selectedUser, setSelectedUser] = React.useState<{ value: string; display: string }>({
        value: _.toString(assignUser?.user_id) || _.toString(currentUser?.user_id) || '',
        display: assignUser?.name || currentUser?.name || '',
    });
    const { data: userData, isLoading: isLoadingUser } = useGetUsersQuery({});
    const [onAssignUser, { isLoading: isLoadingAssign }] = useAssignUserMutation();
    const [warningMsg, setWarningMsg] = useState<string>('');
    const [refreshPage, setRefreshPage] = useState<boolean>(false);
    const { setLoadingConfig } = useLoading();

    const handleOnAssignUser = async () => {
        setLoadingConfig(true, false);
        const result = await onAssignUser({
            idUser: +selectedUser.value,
            idsReviews: [reviewId],
            validation: validationAssign,
            tag: ResponseType.DETAILS,
        }).unwrap();
        setLoadingConfig(false);

        const errorReviewIds = result?.data?.attributes?.errors_review_id;

        if (!_.isNil(errorReviewIds) && !_.isEmpty(errorReviewIds)) {
            setWarningMsg('The review has already been assigned, we recommend refreshing the page to get the updated data.');
            setRefreshPage(true);
            return;
        }
        setLoadingConfig(true, false);
        onRefreshPage();
        onHide();
        setLoadingConfig(false);
    };

    const isLoading = isLoadingAssign || isLoadingUser;

    return (
        <Modal showModal={showModal} setShowModal={onHide} size="medium">
            <div id="modal-assign">
                <h4>{'Assign Review'}</h4>
            </div>
            {}
            <div>
                {isLoading ? (
                    <div className="processing-spinner-assign">
                        <Spinner />
                    </div>
                ) : (
                    <div>
                        {warningMsg !== '' ? (
                            <div className={'warning-msg'}>{warningMsg}</div>
                        ) : (
                            <div>
                                <AutoCompleteUsers
                                    userList={userData?.data ?? []}
                                    selectedUser={selectedUser}
                                    onChangeUser={(val) => setSelectedUser(val)}
                                    selectedReviewNumber={1}
                                    onClick={handleOnAssignUser}
                                />
                            </div>
                        )}
                        <div>
                            <div>
                                <div className="refresh-review-actions">
                                    <Button customClass={'refresh-cancel-btn'} variant="info" onClick={onHide}>
                                        Cancel
                                    </Button>
                                    {refreshPage && (
                                        <Button variant="secondary" onClick={() => onRefreshPage()}>
                                            {'Refresh Review'}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
