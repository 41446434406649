import React, { useEffect, useState } from 'react';
import {
    DetailedReview,
    MapQuestionCategoryToString,
    QuestionCategory,
    QuestionType,
    ReviewStatus,
    Roles,
    SurveyHistory,
    SurveyResponseAnswers,
} from '@reviews/interfaces';
import { ElementTag, ReviewDetailAnswerListToolbar } from './ReviewDetailAnswerListToolbar';
import { TextBox } from '../TextBox/TextBox';
import HistoryForm from '../HistoryForm/HistoryForm';
import { SideBarContainer } from '../SideBarContainer/SideBarContainer';
import * as _ from 'lodash';
import './ReviewDetailAnswerList.scss';
import { ProcessReview } from '../ProcessReview/ProcessReview';

import MaintenanceTicketContainer from '../MaintenanceTicketContainer/MaintenanceTicketContainer';
import { useSelector } from 'react-redux';
import { QueueTypes } from '../ReviewsTable/WorkQueue.utils';

interface ReviewDetailAnswerListProps {
    onResponseSave: (response: string) => void;
    onAnswerEdit: (answer: SurveyResponseAnswers, newValue: string | number, oldValue: string | number, attribute: string) => void;
    review: DetailedReview;
    surveyHistory: SurveyHistory[];
    unitAvgByQuestionCategory: { [category: string]: number };
    isLoadingReview: boolean;
    isLoadingAvg: boolean;
    onOpenReviewModal: () => void;
    onOpenRefreshModal: () => void;
    ticketData: any;
    startTime: number;
    setStartTime: (value: number) => void;
}

export const ReviewDetailAnswerList: React.FC<ReviewDetailAnswerListProps> = (props: ReviewDetailAnswerListProps) => {
    const {
        onAnswerEdit,
        review: {
            answers,
            survey_response_id,
            review_id,
            unit_data,
            unit_id,
            status,
            display_review,
            display_rating,
            display_review_notes,
            display_rating_notes,
            response,
            assigned_to,
            reservation,
        },
        isLoadingAvg,
        surveyHistory,
        isLoadingReview,
        onOpenReviewModal,
        onOpenRefreshModal,
        ticketData,
        startTime,
        setStartTime,
    } = props;

    const lastNight = reservation?.last_night || '';

    const flow = useSelector((state: any) => state.flow);
    const isEmployee = flow.user?.role === Roles.EMPLOYEE;
    const isCloseReview = status === ReviewStatus.PUBLISHED || status === ReviewStatus.SUPPRESSED;

    const isResponseRequired = flow.queue.type === QueueTypes.RESPONSE_REQUIRED;
    const validationAssign = _.isNil(assigned_to);
    const averages = props.unitAvgByQuestionCategory || {};
    const editableCategories = ['OVERALL'];
    const publicReviewAnswer = _.find(answers, (a) => a.type === QuestionType.COMMENT && a.category === QuestionCategory.PUBLIC_REVIEW);
    const overallRating = _.find(answers, (a) => a.type === QuestionType.RATING && a.category === QuestionCategory.OVERALL);
    const generalComment = _.find(answers, (a) => a.type === QuestionType.COMMENT && a.category === QuestionCategory.GENERAL_FEEDBACK);

    const hideTicketAction = isEmployee && (_.isNil(ticketData?.data?.data) || _.isEmpty(ticketData?.data?.data));
    const [sideBarElement, setSideBarElement] = useState({
        [ElementTag.ProcessReview]: !isResponseRequired,
        [ElementTag.HistoryView]: false,
        [ElementTag.MaintenanceTicketView]: isResponseRequired,
    });

    useEffect(() => {
        setSideBarElement({
            [ElementTag.ProcessReview]: !isResponseRequired,
            [ElementTag.HistoryView]: false,
            [ElementTag.MaintenanceTicketView]: isResponseRequired,
        });
    }, [survey_response_id]);

    const isEmptyReview =
        (_.isUndefined(publicReviewAnswer?.answer?.data?.answer) ||
            _.isNil(publicReviewAnswer?.answer?.data?.answer) ||
            publicReviewAnswer?.answer?.data?.answer === '') &&
        (_.isUndefined(publicReviewAnswer?.answer?.data?.title) ||
            _.isNil(publicReviewAnswer?.answer?.data?.title) ||
            publicReviewAnswer?.answer?.data?.title === '');

    const isWithoutRating =
        _.isUndefined(overallRating?.answer?.data?.answer) ||
        _.isNil(overallRating?.answer?.data?.answer) ||
        overallRating?.answer?.data?.answer! < 1;

    const handleToggleSideBarEl = (elementTag: ElementTag) => {
        const newSidebarElements = { ...sideBarElement };
        for (const elementTagKey in sideBarElement) {
            const el = sideBarElement[elementTagKey as ElementTag];
            if (elementTag === elementTagKey) {
                newSidebarElements[elementTagKey] = !el;
            } else {
                newSidebarElements[elementTagKey as ElementTag] = false;
            }
        }
        setSideBarElement(newSidebarElements);
    };

    const handlerOpenReviewModal = () => {
        onOpenReviewModal();
    };

    const initResponse = {
        response: response ?? '',
        display_review_notes: display_review_notes ?? '',
        display_rating_notes: display_rating_notes ?? '',
    };

    const renderCustomOrderCategoryAnswer = () => {
        const customOrderCategory: { [key: string]: number } = {
            OVERALL: 0,
            PUBLIC_REVIEW: 1,
            CLEANLINESS: 2,
            PROPERTY: 3,
            STAFF: 4,
            WIFI: 5,
            LOCATION: 6,
            NPS: 7,
            GENERAL_FEEDBACK: 8,
        };
        return answers.map((a: SurveyResponseAnswers) => {
            return { ...a, customOrderCategory: customOrderCategory[a.category] ?? 0 };
        });
    };

    const renderOverall = () => {
        if (!_.isUndefined(publicReviewAnswer)) {
            if (!isEmployee && !isCloseReview) {
                return (
                    <>
                        <TextBox
                            title={publicReviewAnswer.answer.dismissed ? undefined : publicReviewAnswer.answer.data?.title}
                            text={publicReviewAnswer.answer.dismissed ? undefined : publicReviewAnswer.answer.data?.answer}
                            maxLength={5000}
                            surveyId={survey_response_id}
                            onEdit={(a, b, c) => onAnswerEdit(publicReviewAnswer, a, b, c)}
                            style={'editable'}
                        />
                    </>
                );
            } else {
                return (
                    <>
                        <TextBox
                            title={publicReviewAnswer.answer.dismissed ? undefined : publicReviewAnswer.answer.data?.title}
                            text={publicReviewAnswer.answer.dismissed ? undefined : publicReviewAnswer.answer.data?.answer}
                            maxLength={5000}
                        />
                    </>
                );
            }
        }
        return <></>;
    };
    // TODO: better align the headers
    return (
        <div>
            <div id="table-response-answers" className="card">
                <div className="review-detail-answers-header-bar row m-0 p-0 ">
                    <div className="body-small-bold header-bar-headers col-md-8  col-lg-9 col-xl-10 m-0 p-0 ">
                        <div className={'category'}>
                            <span>Category</span>
                        </div>
                        <div>
                            <span>Rating</span>
                        </div>
                        <div>
                            <span>90d Avg</span>
                        </div>
                        <div className={'comment-header'}>
                            <span>Comment</span>
                        </div>
                    </div>

                    <div className={'col-lg-3  col-xl-2 col-md-4  '}>
                        <ReviewDetailAnswerListToolbar
                            onClickAction={handleToggleSideBarEl}
                            hideTickectAction={hideTicketAction}
                            selectedAction={sideBarElement}
                        />
                    </div>
                </div>

                <div className="row p-0 m-0">
                    <div className="review-detail-answers-list col m-0 p-0">
                        {renderCustomOrderCategoryAnswer()
                            .filter((a) => a.type === QuestionType.RATING || a.category === QuestionCategory.GENERAL_FEEDBACK)
                            .sort((a, b) => a.customOrderCategory - b.customOrderCategory)
                            .map((a: SurveyResponseAnswers, index: number) => (
                                <div key={index} className="body-small-regular review-detail-answers-list-item  ">
                                    <div className={'body-small-bold category'}>
                                        <span>{MapQuestionCategoryToString[a.category]}</span>
                                    </div>

                                    <div>{a.type === QuestionType.COMMENT || a.answer.dismissed ? '' : a.answer.data?.answer}</div>

                                    <div>{isLoadingAvg ? 'Loading...' : averages[a.category] || 'N/A'}</div>
                                    <div className={'body-small-regular comment-column'}>
                                        {editableCategories.includes(a.category) && !_.isUndefined(publicReviewAnswer) ? (
                                            renderOverall()
                                        ) : (
                                            <TextBox
                                                text={
                                                    a.answer.dismissed
                                                        ? ''
                                                        : a.category === QuestionCategory.GENERAL_FEEDBACK
                                                        ? generalComment?.answer?.data?.answer ?? ''
                                                        : a.answer.data?.comment
                                                }
                                                maxLength={5000}
                                                onEdit={undefined}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                    {sideBarElement[ElementTag.HistoryView] && (
                        <SideBarContainer
                            baseStyle={'col-lg-4 col-xl-4 m-0 p-0'}
                            title={'Case History'}
                            onHide={() => handleToggleSideBarEl(ElementTag.HistoryView)}
                            isClose={isCloseReview}
                        >
                            <HistoryForm
                                disableAction={isCloseReview}
                                surveyHistory={surveyHistory}
                                surveyId={survey_response_id}
                                isLoadingReview={isLoadingReview}
                            />
                        </SideBarContainer>
                    )}

                    {sideBarElement[ElementTag.ProcessReview] && (
                        <SideBarContainer
                            baseStyle={'col-lg-4 col-xl-4 m-0 p-0'}
                            title={'Process Review'}
                            onHide={() => handleToggleSideBarEl(ElementTag.ProcessReview)}
                            isClose={isCloseReview}
                        >
                            <ProcessReview
                                reviewId={review_id}
                                survey_response_id={survey_response_id}
                                unitId={unit_id}
                                initDisplayReview={display_review}
                                initDisplayRating={display_rating}
                                initResponse={initResponse}
                                isClose={isCloseReview}
                                emptyReview={isEmptyReview}
                                defaultSuppress={isWithoutRating}
                                isEmployee={isEmployee}
                                onOpenReview={handlerOpenReviewModal}
                                validationAssign={validationAssign}
                                openRefreshModal={onOpenRefreshModal}
                                isLoadingReview={isLoadingReview}
                                startTime={startTime}
                                setStartTime={setStartTime}
                            />
                        </SideBarContainer>
                    )}

                    {sideBarElement[ElementTag.MaintenanceTicketView] && (
                        <SideBarContainer
                            baseStyle={'col-lg-4 col-xl-4 m-0 p-0'}
                            title={'Create Maintenance Ticket'}
                            onHide={() => handleToggleSideBarEl(ElementTag.MaintenanceTicketView)}
                            isClose={isCloseReview}
                        >
                            <MaintenanceTicketContainer
                                UnitData={unit_data}
                                reviewId={review_id}
                                survey_response_id={survey_response_id}
                                overall={(publicReviewAnswer?.answer?.data?.answer as string) ?? ''}
                                isClose={isCloseReview}
                                isEmployee={isEmployee}
                                ticketData={ticketData}
                                reviewStatus={status}
                                history={surveyHistory}
                                validationAssign={validationAssign}
                                openRefreshModal={onOpenRefreshModal}
                                isLoadingReview={isLoadingReview}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                lastNight={lastNight}
                            />
                        </SideBarContainer>
                    )}
                </div>
            </div>
        </div>
    );
};
