import { Route, RouteProps, Routes, BrowserRouter } from 'react-router-dom';
import { Home, ReviewDetail } from './views';
import { QueueTypes } from './components/ReviewsTable/WorkQueue.utils';
import { Roles } from '@reviews/interfaces';

export enum AppRoutes {
    HOME = '/home',
    REVIEW_DETAIL = '/review/:id',
    NEW = '/new',
    PENDING = '/pending',
    RESPONSE_REQUIRED = '/response_required',
    SEARCH = '/search',
    SEARCH_WITH_PARAMS = '/search/?criteria=:criteria&value=:value',
    BULK_REVIEWS = '/bulk_reviews',
    TEAM = '/team',
}

export const queueTypeToAppRoute = (role?: Roles) => {
    return {
        [QueueTypes.NEW]: { index: 0, path: AppRoutes.NEW },
        [QueueTypes.MINE]: { index: 1, path: AppRoutes.PENDING },
        [QueueTypes.RESPONSE_REQUIRED]: { index: 2, path: AppRoutes.RESPONSE_REQUIRED },
        [QueueTypes.SEARCH]: { index: role === Roles.EMPLOYEE ? 0 : 3, path: AppRoutes.SEARCH },
        [QueueTypes.BULK_IMPORT]: { index: 4, path: AppRoutes.BULK_REVIEWS },
        [QueueTypes.MY_TEAM]: { index: 5, path: AppRoutes.TEAM },
    };
};

const routes: RouteProps[] = [
    { path: AppRoutes.HOME, element: <Home /> },
    { path: AppRoutes.REVIEW_DETAIL, element: <ReviewDetail /> },
    { path: AppRoutes.NEW, element: <Home queueType={QueueTypes.NEW} /> },
    { path: AppRoutes.PENDING, element: <Home queueType={QueueTypes.MINE} /> },
    { path: AppRoutes.RESPONSE_REQUIRED, element: <Home queueType={QueueTypes.RESPONSE_REQUIRED} /> },
    { path: AppRoutes.SEARCH || AppRoutes.SEARCH_WITH_PARAMS, element: <Home queueType={QueueTypes.SEARCH} /> },
    { path: AppRoutes.BULK_REVIEWS, element: <Home queueType={QueueTypes.BULK_IMPORT} /> },
    { path: AppRoutes.TEAM, element: <Home queueType={QueueTypes.MY_TEAM} /> },
    { path: '*', element: <Home /> },
];

export const Router = () => (
    <BrowserRouter>
        <Routes>
            {routes.map((route, i) => (
                <Route key={i} {...route} />
            ))}
        </Routes>
    </BrowserRouter>
);
