import { BaseUser, MaintenanceTicket, MaintenanceTicketDetailResponse } from '@reviews/interfaces';
import * as _ from 'lodash';

export const mapToMaintenanceTicketData = (
    responseData: MaintenanceTicketDetailResponse | undefined
): {
    maintenanceTicketData: MaintenanceTicket | null;
    createdByData: BaseUser | null;
} => {
    if (_.isUndefined(responseData) || _.isUndefined(responseData.data)) {
        return {
            maintenanceTicketData: null,
            createdByData: null,
        };
    }

    const mtResponse = responseData.data;

    const attributes = mtResponse[0]?.attributes ?? null;

    if (!attributes) {
        return {
            maintenanceTicketData: null,
            createdByData: null,
        };
    }
    const includedData = responseData.included || [];

    const userData =
        (_.find(includedData, (us) => us.type === 'user' && us.id === attributes.created_by_id)?.attributes as BaseUser) ?? null;

    return {
        maintenanceTicketData: attributes,
        createdByData: userData,
    };
};
