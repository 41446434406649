import React, { useCallback } from 'react';
import { Icon } from '@vacasa/react-components-lib';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import './GoBack.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent } from '../../store/flow';
import { MapQueueTypeToString } from '../ReviewsTable/WorkQueue.utils';
import { debounce } from 'lodash';

interface GoBackProps {
    setStartTime: (value: number) => void;
}

export const GoBack: React.FC<GoBackProps> = (props: GoBackProps) => {
    const { setStartTime } = props;
    const flow = useSelector((state: any) => state.flow);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleGoNext = useCallback(
        debounce(() => {
            const id = flow.next;
            setStartTime(Date.now());
            dispatch(setCurrent({ review_id: id }));
            navigate(AppRoutes.REVIEW_DETAIL.replace(':id', id));
        }, 300),
        [flow, setStartTime, dispatch, navigate]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleGoBack = useCallback(
        debounce(() => {
            navigate(flow.queue.path, { replace: true });
        }, 300),
        [flow.queue.path, navigate]
    );

    return (
        <div className="body-regular card-header-blank d-flex">
            <div onClick={() => handleGoBack()} className={'back-btn p-1'}>
                <Icon.CornerDownLeft height={24} width={24} /> Back to Queue
            </div>
            {flow.next && (
                <div onClick={() => handleGoNext()} className={'back-btn p-1'}>
                    Next Review ({MapQueueTypeToString[flow.queue.type]}) <Icon.ArrowRight height={24} width={24} />
                </div>
            )}
        </div>
    );
};
