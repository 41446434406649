import React, { useEffect, useMemo, useState } from 'react';
import { dicSortFilters, ReviewCountByStars, ReviewsTable } from '..';
import { ApiRequest, useAssignUserMutation, useAutoAssignUserMutation, useGetReviewsQuery, useGetUsersQuery } from '../../services';
import { Icons } from '../../assests/icons';
import './WorkQueue.scss';
import { AutoCompleteUsers } from '../AutoCompleteUsers/AutoCompletUsers';
import { Alert } from '@mui/material';
import { StarCounter } from '@reviews/interfaces';
import { SearchReview } from '../SearchReview/SearchReview';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setDataAndFilters, setEmptySearchParams, setFiltersWhenChangePage, setInitFilters } from '../../store/flow';

import { FollowUpModal } from '../FollowUpModal/FollowUpModal';
import { QueueTypes } from '../ReviewsTable/WorkQueue.utils';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../../contexts/LoadingContext';

interface WorkQueueProps {
    type: QueueTypes;
    startCount?: StarCounter;
}

export const WorkQueue: React.FC<WorkQueueProps> = (props: WorkQueueProps) => {
    const { type, startCount } = props;

    const dispatch = useDispatch();
    const { search } = useLocation();
    const { setLoadingConfig } = useLoading();

    useMemo(() => {
        const searchParams = new URLSearchParams(search);
        dispatch(setEmptySearchParams({ queueType: type }));
        dispatch(setInitFilters({ queueType: type, searchParams }));
    }, []);

    const flow = useSelector((state: any) => state.flow);

    const [skip, setSkip] = React.useState<boolean>(type === QueueTypes.SEARCH);
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<{ value: string; display: string }>({
        value: '',
        display: '',
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [showAlertAutoAssign, setShowAlertAutoAssign] = useState<boolean>(false);
    const [warningAssign, setWarningAssign] = useState<string>('');

    const [request, setRequest] = useState<ApiRequest>(flow.request);

    let { data, error, isLoading, isFetching } = useGetReviewsQuery(request, { skip, refetchOnMountOrArgChange: true });
    const { data: userData, error: errorUser, isLoading: isLoadingUser } = useGetUsersQuery({});
    const [onAssignUser, { isLoading: isLoadingAssign, error: errorAssign }] = useAssignUserMutation();
    const [autoAssignUser, { isLoading: isLoadingAutoAssign }] = useAutoAssignUserMutation();

    const [showFollowUpModal, setShowFollowUpModal] = useState<boolean>(false);
    const [limitRows, setLimitRows] = useState<number>(flow.request.pageSize ?? 10);
    const [page, setPage] = useState(flow.request.pageNumber ?? 0);
    const [currenTicketObj, setCurrentTicketObj] = useState({ maintenanceTicketId: 0, followUpStatus: '', adminTicketId: 0 });

    useEffect(() => {
        if (!_.isNil(data)) {
            dispatch(setDataAndFilters({ response: data, request: request, queueType: type }));
        }
    }, [data]);

    useEffect(() => {
        setSelected([]);
        setRequest(flow.request);
        setLimitRows(flow.request.pageSize);
        setPage(flow.request.pageNumber);
    }, [flow.myWorkFilter]);

    const autoAssignBtn = _.filter(flow.startFilter, (f) => f.active).length === 0 && flow.myWorkFilter;

    const handlePageChange = (page: number, limit: number, sortCriteria: string, sortDirection: string) => {
        const resetLimit = limit !== limitRows;
        setSelected([]);
        setLimitRows(limit);
        setPage(page);
        let request: ApiRequest = {};
        if (!_.isEmpty(sortCriteria)) {
            const isAsc = sortDirection === 'asc';
            const sort = isAsc ? '' : '-';
            setRequest((r) => {
                request = { ...r, pageNumber: resetLimit ? 0 : page, pageSize: limit, sort: `${sort}${dicSortFilters[sortCriteria]}` };
                return request;
            });
        } else {
            setRequest((r) => {
                request = { ...r, pageNumber: resetLimit ? 0 : page, pageSize: limit };
                return request;
            });
        }
        dispatch(setFiltersWhenChangePage({ request }));
    };
    const handleAssignUserAgent = async () => {
        if (selectedUser && selected.length > 0) {
            setShowAlert(false);
            setWarningAssign('');
            setLoadingConfig(true, false);
            const validationFlag = type === QueueTypes.NEW;
            const countAssign = selected.length;
            try {
                const result = await onAssignUser({
                    idUser: +selectedUser.value,
                    idsReviews: selected as number[],
                    validation: validationFlag,
                }).unwrap();

                setSelectedUser({ value: '', display: '' });
                setSelected([]);

                const errorReviewIds = result?.data?.attributes?.errors_review_id;

                if (!_.isNil(errorReviewIds) && !_.isEmpty(errorReviewIds)) {
                    const assignedString = errorReviewIds.toString();
                    const successAssignCount = countAssign - errorReviewIds.length;
                    setWarningAssign(
                        ` ${successAssignCount} reviews were correctly assigned, but the following revisions were already assigned or were processed: ${assignedString}.`
                    );
                    return;
                }
                setShowAlert(true);
            } catch (err) {
                alert('error assigning reviews');
            } finally {
                setLoadingConfig(false);
            }
        }
    };
    const handlerAutoAssign = async () => {
        setShowAlertAutoAssign(false);
        setLoadingConfig(true);
        try {
            await autoAssignUser({}).unwrap();
        } catch (e) {
            setShowAlertAutoAssign(true);
        } finally {
            setLoadingConfig(false);
        }
    };
    const handleFilter = async (newFilters: { filters: { [key: string]: string } }, cleanFilters: boolean = false) => {
        setSelected([]);
        if (type === QueueTypes.SEARCH) {
            setRequest((r) => ({ ...r, ...newFilters }));
            setSkip(false);
            return;
        }
        if (cleanFilters) {
            setRequest((r) => {
                const { filters, ...rest } = r;
                return { ...rest, filters: { ...newFilters.filters } };
            });
            return;
        }
        setRequest((r) => {
            const { filters, ...rest } = r;
            return { ...rest, filters: { ...filters, ...newFilters.filters } };
        });
    };
    const handleSortFilter = (sortFilter: string) => {
        setSelected([]);
        setRequest((r) => ({ ...r, sort: sortFilter }));
    };
    const handleOpenFollowUpModal = (id?: number, followUpStatus?: string, adminTicketId?: number) => {
        setCurrentTicketObj({ maintenanceTicketId: id || 0, adminTicketId: adminTicketId || 0, followUpStatus: followUpStatus || '' });
        setShowFollowUpModal(true);
    };

    const loadingData = isLoading || isLoadingUser || isLoadingAutoAssign;

    if ((type !== QueueTypes.SEARCH && loadingData) || isLoadingAssign) {
        return (
            <div className="loading">
                <Icons.Spinner />
            </div>
        );
    }

    if (error || errorUser || errorAssign) {
        return (
            <div>
                <h1>An error occurred:</h1>
                <div>{JSON.stringify(error)}</div>
            </div>
        );
    }

    if (!data) {
        data = { data: [] };
    }

    return (
        <React.Fragment>
            {showAlert && (
                <Alert className={'mt-1 mb-1'} severity="success" onClose={() => setShowAlert(false)}>
                    reviews assigned correctly
                </Alert>
            )}

            {showAlertAutoAssign && (
                <Alert className={'mb-2'} severity="warning" onClose={() => setShowAlertAutoAssign(false)}>
                    There are no new reviews to assign, try again in a few minutes.
                </Alert>
            )}
            {warningAssign !== '' && (
                <Alert severity="warning" onClose={() => setWarningAssign('')}>
                    {warningAssign}
                </Alert>
            )}

            <div className="top-bar row">
                <div className={'col-xxl-7 mt-2'}>
                    {type === QueueTypes.SEARCH && (
                        <SearchReview userData={userData?.data ?? []} handleSearchFilter={(filter) => handleFilter(filter)} />
                    )}
                    {type !== QueueTypes.SEARCH && !_.isUndefined(startCount) && (
                        <ReviewCountByStars
                            count={{
                                '1': startCount['1'],
                                '2': startCount['2'],
                                '3': startCount['3'],
                                '4': startCount['4'],
                                '5': startCount['5'],
                            }}
                            handleFilter={(filter, cleanFilters) => handleFilter(filter, cleanFilters)}
                        />
                    )}
                </div>
                <div className="col d-flex justify-content-end">
                    {userData && (
                        <AutoCompleteUsers
                            userList={userData?.data ?? []}
                            selectedUser={selectedUser}
                            onChangeUser={(val) => setSelectedUser(val)}
                            selectedReviewNumber={selected.length}
                            onClick={handleAssignUserAgent}
                        />
                    )}
                </div>
            </div>

            {type === QueueTypes.SEARCH && isFetching ? (
                <div className="loading">
                    <Icons.Spinner />
                </div>
            ) : (
                <ReviewsTable
                    data={data!}
                    onPageSizeChange={handlePageChange}
                    onPageLimitChange={handlePageChange}
                    onChangeSortFilter={(filter) => handleSortFilter(filter)}
                    onChangeSelectedReviews={(val) => setSelected(val)}
                    selectedReviews={selected}
                    limitRows={limitRows}
                    skipEmptyMessage={skip}
                    page={page}
                    typeQueue={type}
                    totalCount={data?.meta?.pagination.count ?? 10}
                    emptyBtnHandler={type === QueueTypes.MINE ? handlerAutoAssign : undefined}
                    loadingData={loadingData}
                    onOpenFollowUpModal={handleOpenFollowUpModal}
                    autoAssignBtn={autoAssignBtn}
                />
            )}
            {showFollowUpModal && (
                <div>
                    <FollowUpModal
                        showModal={showFollowUpModal}
                        onHide={() => setShowFollowUpModal(false)}
                        maintenanceTicketObj={currenTicketObj}
                    />
                </div>
            )}
        </React.Fragment>
    );
};
